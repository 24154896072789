<template>
  <div id="UserPassword">
    <b-input-group>
      <template v-slot:prepend>
        <b-input-group-text class="login-prepend">
          <b-icon-lock-fill class="login-icon" />
        </b-input-group-text>
      </template>
      <b-form-input
        :type="type"
        @focusin="focus = true"
        @focusout="focus = false"
        class="password-input"
        placeholder="Senha"
        v-model="inputValue"
        v-on:input="$emit('user-password-change', inputValue)"
      />
      <template v-slot:append>
        <b-input-group-text
          @click="changeType()"
          v-bind:class="focus ? 'password-append-focus' : 'password-append'"
        >
          <b-icon-eye-slash v-if="password" />
          <b-icon-eye v-else />
        </b-input-group-text>
      </template>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'UserPassword',
  components: {},
  props: {
    reset: Number,
  },
  data() {
    return {
      inputValue: '',
      type: 'password',
      password: true,
      focus: false,
    };
  },
  methods: {
    changeType() {
      this.type = this.type === 'password' ? 'text' : 'password';
      this.password = !this.password;
    },
  },
  watch: {
    reset() {
      this.inputValue = '';
    },
  },
};
</script>

<style scoped>
#UserPassword {
  margin-bottom: 2em;
}
.password-input {
  background-color: #ffffff;
  border: solid 1px #ced4da;
  border-right: none;
  border-left: solid 1px #c31d1f;
}
.password-input-focus {
  background-color: #ffffff;
  border-color: #c31d1f;
  box-shadow: none;
}
.password-input:focus {
  background-color: #ffffff;
  border-color: #c31d1f;
  box-shadow: none;
}
.password-append {
  cursor: pointer;
  background-color: #ffffff;
  border: solid 1px #ced4da;
  border-left: none;
  padding: 0 0.5em;
}
.password-append-focus {
  cursor: pointer;
  background-color: #ffffff;
  border: solid 1px #c31d1f;
  border-left: none;
  padding: 0 0.5em;
}
</style>
