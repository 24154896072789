<template>
  <div id="Modal">
    <b-modal
        :id="id"
        @ok="handleOk"
        @cancel="handleCancel"
        cancelTitle="Cancelar"
        cancelVariant="outline-secondary"
        centered
        header-bg-variant="dark"
        header-text-variant="light"
        okTitle="Salvar"
        okVariant="danger"
        ref="modal"
        size="xl"
        title="Faturamento de NF de Serviço de Telecomunicações"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
       
       <v-row>

          <b-col cols="12" sm="1" md="1" class="no-padding ma-auto pt-4">
            <v-btn v-show="disabledEdit" style="width:100%" @click="abilitaEdicao()">Editar</v-btn>
            <v-btn v-show="!disabledEdit" style="width:100%;" @click="limpaNota">Limpar</v-btn>
          </b-col>

        </v-row>

        <b-row>

          <b-col cols="12" sm="4" md="4" class="no-padding">
            <InterfaceSelect
            CampoObrigatorio = true
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            topLabel="Filial:"
            :entries="filiais"
            itemText="fil_descricao"
            itemValue="fil_descricao"
            :data="filialSelect"
            @update:value="filialSelect = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2" class="no-padding">
            <label>Data emissão:</label>
            <DefaultDatePicker
            :classe="disabledEdit ? 'disabled' : ''"
            :label="formataData(data_emissao, 'DD/MM/YYYY')"
            :disabled="disabledEdit"
            :data="data_emissao"
            :maxDate="dataHoje"
            @update:returnDate="data_emissao = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2" class="no-padding">
            <InterfaceText
            :classe="disabledEdit ? 'disabled' : ''"
            :disabled= true
            topLabel="Ano/mês referência:"
            :label="data_ref==''?'AAMM':String(data_ref)"
            type="text"
            :data="data_ref"
            @update:value="data_ref = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2" class="no-padding">
            <InterfaceSelect
            CampoObrigatorio = true
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            topLabel="Modelo de Nota:"
            :entries="modelosNota"
            itemText="descricao"
            itemValue="codigo"
            :data="modeloNotaSelect"
            @update:value="modeloNotaSelect = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2" class="no-padding">
            <InterfaceText
            classe = 'disabled'
            :disabled = true
            topLabel="Série:"
            :label="serie"
            :data="serie"
            @update:value="serie = $event"/>
          </b-col>

        </b-row>

        <b-row>

          <b-col cols="12" sm="4" md="4" class="no-padding">
            <InterfaceSelect
            CampoObrigatorio = true
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            topLabel="Tipo de utilização:"
            :entries="tiposUtilizacao"
            itemText="descricao"
            itemValue="codigo"
            :data="utilizacaoSelect"
            @update:value="utilizacaoSelect = $event"/>
          </b-col>

          <b-col cols="12" sm="4" md="4" class="no-padding">
            <InterfaceSelect
            CampoObrigatorio = true
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            topLabel="Tipo cliente:"
            :entries="tiposCliente"
            itemText="descricao"
            itemValue="codigo"
            :data="tipoClienteSelect"
            @update:value="tipoClienteSelect = $event"/>
          </b-col>

          <b-col cols="12" sm="4" md="4" class="no-padding">
            <InterfaceSelect
            CampoObrigatorio = true
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            topLabel="Tipo isenção:"
            :entries="tiposIsencao"
            itemText="descricao"
            itemValue="codigo"
            :data="tipoIsencaoSelect"
            @update:value="tipoIsencaoSelect = $event"/>
          </b-col>

        </b-row>

        <b-row>

          <b-col cols="12" sm="12" md="12" class="no-padding">
            <ClientSearch
              CampoObrigatorio= true
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              :labelCodigo="clienteSelect.pes_codigo != '' ? String(clienteSelect.pes_codigo) : 'Código'"
              :labelNome="clienteSelect.pes_nome != '' ? clienteSelect.pes_nome : 'Nome do cliente'"
              :limpaDados="limpaCliente"
              :data='clienteSelect'
              itemText='pes_nome'
              itemValue='pes_codigo'
              @update:value="clienteSelect = $event"/>
          </b-col>

        </b-row>

        <b-row>

          <b-col cols="12" sm="2" md="2" class="no-padding">
            <InterfaceSelect
            :CampoObrigatorio="modeloNotaSelect.codigo==21?true:false"
            :classe="modeloNotaSelect.codigo==21?disabledEdit ? 'disabled obrigatorio' : 'obrigatorio': disabledEdit ? 'disabled ' : ''"
            :disabled="disabledEdit"
            topLabel="Terminal telefônico:"
            :entries="listaTerminais"
            itemText='peco_telefone'
            itemValue='peco_telefone'
            :data="terminalSelect"
            @update:value="terminalSelect = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2" class="no-padding">
            <InterfaceSelect
            topLabel="UF Habilitação:"
            :classe="disabledEdit ? 'disabled' : ''"
            :disabled="disabledEdit"
            :entries="listaUF"
            itemText='descricao'
            itemValue='sigla'
            :data="ufHabilitacao"
            @update:value="ufHabilitacao = $event"/>
          </b-col>

          <b-col cols="12" sm="3" md="3" class="no-padding">
            <InterfaceSelect
            topLabel="Contrato:"
            :classe="disabledEdit ? 'disabled' : ''"
            :disabled="disabledEdit"
            label="--NENHUM--"
            :entries="listaContratos"
            itemText='con_titulo'
            itemValue='con_codigo'
            :data="contratoSelect"
            @update:value="contratoSelect = $event"/>
          </b-col>

           <b-col cols="12" sm="2" md="2" class="no-padding">
            <InterfaceText
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            CampoObrigatorio = true
            topLabel="ID consumidor:"
            min=0
            maxlength= 12
            :data="infoCliente.id"
            @update:value="infoCliente.id = $event"/>
          </b-col>

          <b-col cols="12" sm="3" md="3" class="no-padding">
            <InterfaceText
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            CampoObrigatorio = true
            topLabel="Fatura comercial:"
            min=0
            maxlength= 20
            :label="faturaComercial"
            :data="faturaComercial"
            @update:value="faturaComercial = $event"/>
          </b-col>

        </b-row>

        <b-row v-show="false">

          <b-col cols="12" sm="3" md="3" class="no-padding">
            <InterfaceSelect
            CampoObrigatorio = true
            :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
            :disabled="disabledEdit"
            topLabel="Situação documento:"
            :entries="tiposSituacaoDocumento"
            itemText="descricao"
            itemValue="codigo"
            :data="situacaoDocumento"
            @update:value="situacaoDocumento = $event"/>
          </b-col>

          <b-col cols="12" sm="9" md="9">
            <InterfaceText
            :classe="liberaInfoAdicionais ? 'disabled' : ''"
            maxlength= 30
            topLabel="Informações adicionais:"
            label="AAMM_MO_SSS_NNNNNNNNN_AAAAMMDD"
            :disabled="liberaInfoAdicionais"
            :data="infoAdicionais"/>
          </b-col>
          
        </b-row>

        <b-row>

          <b-col cols="12" sm="12" md="12">
            <InterfaceText
            :classe="disabledEdit ? 'disabled' : ''"
            :disabled="disabledEdit"
            topLabel="Observação:"
            :data="observacao"
            @update:value="observacao = $event"/>
          </b-col>

        </b-row>

        <div class="box">

          <b-row>
            <label class="nameBox">Endereço do Cliente</label>
          </b-row>

          <b-row>
            
            <b-col cols="12" sm="3" md="3">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="Telefone de contato:"
              :label="infoCliente.telefone"
              :data="infoCliente.telefone"
              @update:value="infoCliente.telefone = $event"/>
            </b-col>

            <b-col cols="12" sm="9" md="9" class="no-padding">
              <InterfaceSelect
              CampoObrigatorio = true
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              topLabel="Endereço:"
              :entries="listaEnderecosCliente"
              itemText='pee_logradouro'
              itemValue='pee_codigo'
              :data="enderecoSelect"
              @update:value="enderecoSelect = $event"/>
            </b-col>

          </b-row>

          <b-row>
            
            <b-col cols="12" sm="3" md="3">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="CEP:"
              :label="infoCliente.cep"
              :data="infoCliente.cep"
              @update:value="infoCliente.cep = $event"/>
            </b-col>

            <b-col cols="12" sm="9" md="9">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="Logradouro:"
              :label="infoCliente.logradouro"
              :data="infoCliente.logradouro"
              @update:value="infoCliente.logradouro = $event"/>
            </b-col>

          </b-row>

          <b-row>
            
            <b-col cols="12" sm="2" md="2">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="Número:"
              :label="infoCliente.numero"
              :data="infoCliente.numero"
              @update:value="infoCliente.numero = $event"/>
            </b-col>

            <b-col cols="12" sm="3" md="3">
              <InterfaceText
              :classe="disabledEdit ? 'disabled' : ''"
              :disabled="disabledEdit"
              topLabel="Complemento:"
              :label="infoCliente.complemento"
              :data="infoCliente.complemento"
              @update:value="infoCliente.complemento = $event"/>
            </b-col>

            <b-col cols="12" sm="3" md="3">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="Bairro:"
              :label="infoCliente.bairro"
              :data="infoCliente.bairro"
              @update:value="infoCliente.bairro = $event"/>
            </b-col>

            <b-col cols="12" sm="1" md="1">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="UF:"
              :label="infoCliente.uf"
              :data="infoCliente.uf"
              @update:value="infoCliente.uf = $event"/>
            </b-col>

            <b-col cols="12" sm="3" md="3">
              <InterfaceText
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              CampoObrigatorio = true
              topLabel="Município:"
              :label="infoCliente.municipio"
              :data="infoCliente.municipio"
              @update:value="infoCliente.municipio = $event"/>
            </b-col>

          </b-row>

        </div>

        <div class="box" v-show="!disabledEdit">

          <b-row>
            <label class="nameBox">Adicionar Serviços</label>
          </b-row>

          <v-row>

            <b-col cols="12" sm="3" md="3">
              <InterfaceText
              classe = 'disabled'
              :disabled = true
              topLabel="Cód. classificação item:"
              :data="editedServico.codClassificacao"
              @update:value="editedServico.codClassificacao = $event"/>
            </b-col>

            <!--<b-col cols="12" sm="6" md="6" class="no-padding">
            <InterfaceSelect
              CampoObrigatorio = true
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit"
              topLabel="CFOP:"
              :entries="tiposCFOP"
              itemText='cfop_descricao'
              itemValue='cfop_codigo'
              :data="editedServico.cfop"
              @update:value="editedServico.cfop = $event"/>
            </b-col>-->

            <b-col cols="12" sm="9" md="9" class="no-padding">
              <label>CFOP:</label>
              <v-select
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              auto-select-first
              :disabled="disabledEdit"
              v-model="editedServico.cfop"
              :items="tiposCFOP"
              hide-no-data
              hide-selected
              itemText='cfop_descricao'
              itemValue='cfop_codigo'
              hide-details
              return-object
              >
                <template v-slot:prepend-inner>
                  <img
                    class="img-pd-0"
                    style="vertical-align:top;"
                    width="20"
                    height="49"
                    alt=""
                    src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>

                <template slot="selection" slot-scope="data">
                  {{ data.item.cfop_codigo }} - {{ data.item.cfop_descricao }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.cfop_codigo }} - {{ data.item.cfop_descricao }}
                </template>

              </v-select>
            </b-col>

          </v-row>

          <v-row>

            <b-col cols="12" sm="12" md="12" class="no-padding">
              <ServiceSearch
              :classe="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              :disabled="disabledEdit || edicaoServico"
              :labelCodigo="editedServico.codigo != '' ? String(editedServico.codigo) : 'Código'"
              :labelNome="editedServico.descricao != '' ? editedServico.descricao : 'Nome do serviço'"
              :limpaDados="limpaServico"
              :data='servicoSelect'
              @update:value="servicoSelect = $event"/>
            </b-col>

          </v-row>

          <v-row>

            <b-col cols="12" sm="3" md="3" class="pl-2 pr-3">
              <label>Quantidade:</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="quantidadeID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.quantidade"
              v-money="quantidade"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Valor unitário (R$):</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="valorUnitarioID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.valorUnitario"
              v-money="money"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2">
              <InterfaceText
              :classe="disabledEdit ? ' text-right disabled' : 'text-right'"
              :disabled= true
              topLabel="Total (R$):"
              id="totalID"
              v-money="money"
              :data="editedServico.total"/>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3" v-show="!editedServico.tributado == true">
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-3" v-show="editedServico.tributado == true">
              <label>Base de cálculo (R$):</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="baseCalculoID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.baseCalculo"
              v-money="money"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="1" md="1">
            </b-col>

            <b-col cols="12" sm="2" md="2">
                <v-checkbox
                  class="checkboxTributado"
                  :disabled="disabledEdit"
                  v-model="editedServico.tributado"
                  label="Tributado"
                ></v-checkbox>
            </b-col>

            <b-col cols="12" sm="1" md="1" class="no-padding">
            </b-col>

          </v-row>

          <v-row v-show="editedServico.tributado == true">

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Alíquota ICMS:</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="aliIcmsID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.aliIcms"
              v-money="aliquota2"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Valor ICMS (R$):</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="valIcmsID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.valIcms"
              v-money="money"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Alíquota PIS:</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="aliPisID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.aliPis"
              v-money="aliquota4"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Valor PIS (R$):</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="valPisID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.valPis"
              v-money="money"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Alíquota COFINS:</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="aliCofinsID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.aliCofins"
              v-money="aliquota4"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

            <b-col cols="12" sm="2" md="2" class="pl-2 pr-3">
              <label>Valor COFINS (R$):</label>
              <v-text-field
              :class="disabledEdit ? 'disabled obrigatorio' : 'obrigatorio'"
              solo
              id="valCofinsID"
              :disabled="disabledEdit"
              v-model.lazy="editedServico.valCofins"
              v-money="money"
              hide-details
              >
                <template v-slot:prepend-inner>
                  <img style="vertical-align:top; padding:0px;" width="20" height="49" alt="" 
                  src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>

          </v-row>

          <v-row>
            <b-col cols="12" sm="3" md="3" class="no-padding ma-auto pt-4">
            </b-col>

            <b-col cols="12" sm="3" md="3" class="no-padding ma-auto pt-4">
              <v-btn style="width:100%" :disabled="disabledEdit" @click="limpaItem()">Limpar</v-btn>
            </b-col>

            <b-col cols="12" sm="3" md="3" class="no-padding ma-auto pt-4">
              <v-btn style="width:100%; background-color: #474e55; color: #ffff"    :disabled="disabledEdit" @click="addItem(item)">Adicionar</v-btn>
            </b-col>

          </v-row>

        </div>

         <div class="box">

            <b-row>
              <label class="nameBox">Serviços</label>
            </b-row>

            <v-row>

            <b-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="listaServicosSelect"
                :items-per-page="999"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="numOrdem"
                show-expand
                hide-default-footer
                class="elevation-1 estilo-tabela"
                :loading="loadingTabela"
                loading-text="Carregando...">

                <template v-slot:top>

                  <v-dialog v-model="dialogDelete" max-width="530px">
                    <v-card>
                      <v-card-title class="headline">
                        Tem certeza que quer excluir esse serviço?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </template>

                <template v-slot:item="{ item , isExpanded}">
              <tr 
              :style="getColor(item)">
                <td>{{item.numOrdem}}</td>
                <td>{{item.codClassificacao}}</td>
                <td>{{item.cfop.cfop_codigo}}</td>
                <td>{{item.codigo}}</td>
                <td>{{item.descricao}}</td>
                <td>{{item.unidade}}</td>
                <td class="text-right">{{item.valorUnitario}}</td>
                <td class="text-right">{{item.quantidade}}</td>
                <td class="text-right">{{item.total}}</td>
                <td>
                  <span class="text-right" v-show="item.tributado == true">Sim</span>
                  <span class="text-right" v-show="item.tributado == false">Não</span>
                </td>
                <td class="text-right">
                  <div style="display: inline-flex;">
                    <v-icon 
                      v-show="item.tributado == true"
                      small 
                      class="mr-2 icon_acoes" 
                      @click="handleExpansion(item, isExpanded)">
                        {{ isExpanded ? 'mdi-close' : 'mdi-magnify' }}
                    </v-icon>
                    <div v-if="item.somaTributos!='0'"> {{item.somaTributos}} </div>
                    <div v-else>0,00</div>
                  </div>
                </td>
                <td>
                  <v-icon
                    small
                    class="mr-2 icon_acoes"
                    :disabled="disabledEdit"
                    @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2 icon_acoes"
                    :disabled="disabledEdit"
                    @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>


                <template v-slot:expanded-item="{ headers, item }">

                  <td :colspan="headers.length">
                    <table style="width:100%">
                      <v-data-table
                      :headers="headersImpostos"
                      :items="[item]"
                      :items-per-page="999"
                      hide-default-footer
                      class="elevation-1 estilo-tabela">

                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-right">{{item.baseCalculo}}</td>
                          <td class="text-right">{{item.aliIcms}}</td>
                          <td class="text-right">{{item.aliPis}}</td>
                          <td class="text-right">{{item.aliCofins}}</td>
                          <td class="text-right">{{item.valIcms}}</td>
                          <td class="text-right">{{item.valPis}}</td>
                          <td class="text-right">{{item.valCofins}}</td>
                        </tr>
                      </template>

                      </v-data-table>
                    </table>
                  </td>

                </template>

                <template v-slot:no-data>
                  <h4 class="mb-1 mt-1">Nenhum serviço adicionado</h4>
                </template>
                
            </v-data-table>
            </b-col>

          </v-row>

        </div>

        <v-row>

          <v-data-table
          :headers="headersTotais"
          :items="totaisServicos"
          :items-per-page="999"
          item-key="codigo"
          hide-default-footer
          class="elevation-1 estilo-tabela">

          <template v-slot:item.valor="{ item }">
            {{item.valor}}
          </template>
              
          </v-data-table>

        </v-row>

      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import swal from 'sweetalert2';
import User from "../../services/api/core/user";
import DefaultDatePicker from '@/components/generics/DefaultDatePicker';
import ProductListForm from "@/components/list-components/ProductListForm"
import GenericTable from "@/components/tables/GenericTable";
import apiInstance from "../../services/api/config";
import InterfaceSelect from '@/components/generics/InterfaceSelect';
import InterfaceText from '@/components/generics/InterfaceText';
import ServiceSearch from "@/components/search-components/ServiceSearch";
import ClientSearch from "@/components/search-components/ClientSearch";
import {VMoney} from 'v-money'

export default {
  name: 'Modal',
  components: {
    GenericTable,
    ProductListForm,
    DefaultDatePicker,
    InterfaceSelect,
    InterfaceText,
    ServiceSearch,
    ClientSearch,
    User,
  },
  props: ['id', 'edit', 'item', 'limpadados'],
  data() {
    return {
      disabledEdit: false,
      edicaoServico: false,
      singleExpand: true,
      backspace: false,
      expanded: [],
      loadingTabela: false,
      liberaInfoAdicionais: true,
      limpaServico: 0,
      limpaCliente: 0,
      numOrdemFinal: 1,
      codigo: "",
      dataHoje: "",
      data_emissao: "",
      observacao: "",
      data_ref: "",
      serie: "",
      user: '',
      event: '',
      infoAdicionais: "",
      filialSelect: {},
      terminalSelect: {},
      modeloNotaSelect: {},
      utilizacaoSelect: {},
      tipoClienteSelect: {},
      tipoIsencaoSelect: {},
      servicoSelect: {},
      enderecoSelect:{},
      contratoSelect: {},
      servicos:[],
      filiais: [],
      clientes:[],
      tiposCFOP: [],
      listaTerminais: [],
      listaContratos: [],
      listaEnderecosCliente: [],
      listaServicosSelect: [],
      salvaServicos: [],
      clienteSelect:{
        pes_nome: "",
        pes_codigo: "",
      },
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
      aliquota2: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
      aliquota4: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 4,
        masked: false
      },
      quantidade: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 3,
        masked: false
      },
      impostos:{
        aliIcms: '0,00',
        aliCofins: '0,0000',
        aliPis: '0,0000',
      },
      enviaImpostos:{
        bcICMS: '0,00',
        ICMSDestacado: '0,00',
        totalSemIcms: '0,00',
      },
      faturaComercial: '',
      infoCliente:{
        nome: '',
        id: '',
        ie: '',
        telefone: '',
        identificador: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        uf: '',
        municipio: '',
        cep: '',
        tipo: '',
      },
      defaultCliente:{
        nome: '',
        id: '',
        ie: '',
        telefone: '',
        identificador: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        uf: '',
        municipio: '',
        cep: '',
        tipo: '',
      },
      editedServico: {
        codigo: '',
        //codReferencia: '',
        codClassificacao: '',
        descricao: '',
        cfop: {},
        quantidade: '0,00',
        valorUnitario: '0,00',
        total: '0,00',
        baseCalculo: '0,00', 
        totalTributado: '0,00',
        unidade: '',
        numOrdem: '',
        aliIcms: '0,00',
        valIcms: '0,00',
        aliCofins: '0,0000',
        valCofins: '0,00',
        aliPis: '0,0000',
        valPis: '0,00',
        somaTributos: '0,00',
        tributado: true,
      },
      defaultServico: {
        codigo: '',
        //codReferencia: '',
        codClassificacao: '',
        descricao: '',
        cfop: {},
        quantidade: '0,00',
        valorUnitario: '0,00',
        total: '0,00',
        baseCalculo: '0,00', 
        totalTributado: '0,00',
        unidade: '',
        numOrdem: '',
        aliIcms: '0,00',
        valIcms: '0,00',
        aliCofins: '0,0000',
        valCofins: '0,00',
        aliPis: '0,0000',
        valPis: '0,00',
        somaTributos: '0,00',
        tributado: true,
      },
      
      situacaoDocumento: 'N',
      modelosNota:[
        {codigo: '21',descricao: '21 - NFSC', valor:'NFSC'},
        {codigo: '22',descricao: '22 - NFST', valor:'NFST'},
      ],
      ufHabilitacao: {descricao: 'PR - Paraná', sigla: 'PR'},
      listaUF: [
        {descricao: 'AC - Acre', sigla: 'AC'},
        {descricao: 'AL - Alagoas', sigla: 'AL'},
        {descricao: 'AP - Amapá', sigla: 'AP'},
        {descricao: 'AM - Amazonas', sigla: 'AM'},
        {descricao: 'BA - Bahia', sigla: 'BA'},
        {descricao: 'CE - Ceará', sigla: 'CE'},
        {descricao: 'DF - Distrito Federal', sigla: 'DF'},
        {descricao: 'ES - Espírito Santo', sigla: 'ES'},
        {descricao: 'GO - Goiás', sigla: 'GO'},
        {descricao: 'MA - Maranhão', sigla: 'MA'},
        {descricao: 'MT - Mato Grosso', sigla: 'MT'},
        {descricao: 'MS - Mato Grosso do Sul', sigla: 'MS'},
        {descricao: 'MG - Minas Gerais', sigla: 'MG'},
        {descricao: 'PA - Pará', sigla: 'PA'},
        {descricao: 'PB - Paraíba', sigla: 'PB'},
        {descricao: 'PR - Paraná', sigla: 'PR'},
        {descricao: 'PE - Pernambuco', sigla: 'PE'},
        {descricao: 'PI - Piauí', sigla: 'PI'},
        {descricao: 'RJ - Rio de Janeiro', sigla: 'RJ'},
        {descricao: 'RN - Rio Grande do Norte', sigla: 'RN'},
        {descricao: 'RS - Rio Grande do Sul', sigla: 'RS'},
        {descricao: 'RO - Rondônia', sigla: 'RO'},
        {descricao: 'RR - Roraima', sigla: 'RR'},
        {descricao: 'SC - Santa Catarina', sigla: 'SC'},
        {descricao: 'SP - São Paulo', sigla: 'SP'},
        {descricao: 'SE - Sergipe', sigla: 'SE'},
        {descricao: 'TO - Tocantins', sigla: 'TO'},
      ],
      totaisServicos: [
        {descricao: 'Total de serviços tributados',valor: 0}, 
        {descricao: 'Outros Valores',valor: 0},
        {descricao: 'Valores isentos',valor: 0}, 
        {descricao: 'Valor total da nota',valor: 0},
        {descricao: 'Valor total dos tributos',valor: 0}, 
      ],
      tiposUtilizacao: [
        {codigo: '1',descricao: '1 - Telefonia'},
        {codigo: '2',descricao: '2 - Comunicação de dados'}, 
        {codigo: '3',descricao: '3 - TV por assinatura'}, 
        {codigo: '4',descricao: '4 - Provimento de acesso à internet'},
      ],
      tiposSituacaoDocumento: [
        {codigo: 'C',descricao: 'C - Documento fiscal complementar'},
        {codigo: 'N',descricao: 'N - Normal'},
        {codigo: 'R',descricao: 'R - Substituição de documento fiscal cancelado no mesmo período de apuração'}, 
        {codigo: 'S',descricao: 'S - Documento fiscal cancelado dentro do período de apuração'}, 
      ],
      tiposCliente: [
        {codigo: '1',descricao: '1 - Comercial'}, 
        {codigo: '2',descricao: '2 - Industrial'}, 
        {codigo: '3',descricao: '3 - Residência/Pessoa Física'}, 
        {codigo: '4',descricao: '4 - Produtor Rural'},
        {codigo: '5',descricao: '5 - Órgão da administração pública estadual direta e suas fundações e autarquias'},
        {codigo: '6',descricao: '6 - Prestador de serviço de telecomunicações'},
        {codigo: '7',descricao: '7 - Missões Diplomáticas, Repartições Consulares e Organismos Internacionais'},
        {codigo: '8',descricao: '8 - Igrajas e Templos de qualquer natureza'},
        {codigo: '99',descricao: '99 - Outros não especificados anteriormente'}
      ],
      tiposIsencao: [
        {codigo: '0',descricao: '0 - Sem Isenção/Redução'}, 
        {codigo: '1',descricao: '1 - Programa Governo Eletrônico - Serviço de Atendimento ao Cidadão GESAC (Convênio ICMS 141/07)'}, 
        {codigo: '2',descricao: '2 - Programa Internet Popular (Convênio ICMS 38/09)'}, 
        {codigo: '3',descricao: '3 - Programa Internet Destinada às Escolas Públicas Federais, Estaduais, Distritais e Municipais (Convênio ICMS 47/08)'}, 
        {codigo: '4',descricao: '4 - Programa Acesso Individual Classe Especial - AICE (Convênio ICMS 16/12)'},
        {codigo: '5',descricao: '5 - Prestação de Serviço de Televisão por Assinatura (Convênio ICMS 57/99)'},
        {codigo: '6',descricao: '6 - Prestação de Serviço de Monitoramento e Rastreamento de Veículo e Carga (Convênio ICMS 137/06)'},
        {codigo: '7',descricao: '7 - Prestação de Serviço de Provimento de Acesso à Internet (Convênio ICMS 78/01)'},
        {codigo: '99',descricao: '99 - Outras'}
      ],
      editedIndex: -1,
      total: 0,
      dialogDelete: false,
      headers: [
        {text: 'Cód. ref.', value: 'numOrdem', sortable: false},
        {text: 'Classificação', value: 'codClassificacao', sortable: false},
        {text: 'CFOP', value: 'cfop.cfop_codigo', sortable: false},
        {text: 'Cód.', value: 'codigo', sortable: false},
        {text: 'Serviço', value: 'descricao', sortable: false},
        {text: 'Unidade', value: 'unidade', sortable: false},
        {text: 'Val. Unitário (R$)', value: 'valorUnitario', align: 'right', sortable: false},
        {text: 'Quant.', value: 'quantidade', align: 'right', sortable: false},
        {text: 'Total (R$)', value: 'total', align: 'right', sortable: false},
        {text: 'Tributado', value: 'tributado', sortable: false},
        {text: 'Tributos (R$)', value: 'data-table-expand', align: 'right', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false, width: '7%'}
      ],
      headersImpostos: [
        {text: 'Base de Cálculo (R$)', value: 'baseCalculo', sortable: false, align: 'right'}, 
        {text: 'Alíq. ICMS (%)', value: 'aliIcms', sortable: false, align: 'right'},  
        {text: 'Alíq. PIS (%)', value: 'aliPis', sortable: false, align: 'right'},
        {text: 'Alíq. COFINS (%)', value: 'aliCofins', sortable: false, align: 'right'},
        {text: 'Val. ICMS (R$)', value: 'valIcms', sortable: false, align: 'right'},
        {text: 'Val. PIS (R$)', value: 'valPis', sortable: false, align: 'right'}, 
        {text: 'Val. COFINS (R$)', value: 'valCofins', sortable: false, align: 'right'},
      ],
      headersTotais: [
        {text: 'Descricao', value: 'descricao', sortable: false},
        {text: 'Valor (R$)', value: 'valor', sortable: false, align: 'right'},
      ],
      num:{
        quantidade: 0,
        aliPis: 0,
        aliCofins: 0,
        aliIcms: 0,
        valPis: 0,
        valCofins: 0,
        valIcms: 0,
        valorUnitario: 0,
        somaTributos: 0,
        baseCalculo: 0,
      }
    };
  },
  watch:{
    limpadados(){
      this.limpaNota();
    },
    servicoSelect(){
      if(this.servicoSelect != null && this.servicoSelect.pro_descricao != ''){
        if(this.servicoSelect.length != 0 && this.edicaoServico == false){
          this.completaEditedServico()
        }
      }
    },
    clienteSelect(){
      if(this.clienteSelect != null){
        this.listaTerminais = this.clienteSelect.contact
        if(this.clienteSelect.contact != undefined){
          var telefone
          for(var i = 0; i < this.clienteSelect.contact.length ; i++){
            if(this.clienteSelect.contact[i].peco_telefone != ''){
              telefone = this.clienteSelect.contact[i].peco_telefone
              telefone = telefone.replace(/[^0-9]+/g,'')
              this.listaTerminais[i].peco_telefone = telefone
            }
          }
          this.infoCliente.nome = this.clienteSelect.pes_nome
          this.infoCliente.tipo = this.clienteSelect.pes_tipo
          this.infoCliente.identificador = this.clienteSelect.pes_identificador
          if(this.infoCliente.tipo == 0){
            this.infoCliente.tipo = 2
          }
          if(Number(this.infoCliente.identificador) == 0){
            this.infoCliente.tipo = this.infoCliente.tipo==1?3:4
          }
          this.infoCliente.codigo = this.clienteSelect.pes_codigo
          this.infoCliente.ie = this.clienteSelect.pes_inscricao_estadual
          this.infoCliente.ie = this.infoCliente.ie.replace(/[^0-9]+/g,'')
          this.infoCliente.telefone = this.clienteSelect.contact[0].peco_telefone
          this.listaEnderecosCliente = this.clienteSelect.address
          if(this.filialSelect.fil_codigo != ''){
            this.searchContrato()
          }
        }
      }
      if(this.disabledEdit == true){
        this.infoCliente.id = this.item.nfst_cod_id_consumidor_assinante
      }
    },
    modeloNotaSelect(){
      if(this.modeloNotaSelect.valor != undefined){
        if (this.filialSelect == null) {
          swal.fire(
              '',
              'Por favor, selecione uma filial',
              'warning',
          );
        }else{
          apiInstance
            .get('/fiscal/Sdf?filial=' + this.filialSelect.fil_codigo + '&dof_codigo=' + this.modeloNotaSelect.valor)
            .then(res => {
              this.serie = res.data.data[0].sdf_serie;
            });
        }
      }
    },
    contratoSelect(){
      if(this.contratoSelect != null){
        this.infoCliente.id = this.contratoSelect.con_numero_contrato
        this.listaServicosSelect = []
        this.numOrdemFinal = 1
        
        for(var i = 0 ; i < this.contratoSelect.contrato_servicos.length ; i++){
          apiInstance
              .get('/service/' + this.contratoSelect.contrato_servicos[0].servicos.pro_codigo)
              .then(res => {
                  this.servicoSelect = res.data.data[0];
                  this.completaEditedServico()
                  document.getElementById("quantidadeID").value = String(Number(this.contratoSelect.contrato_servicos[0].servicos.variedade[0].product_variety_pack[0].pve_quantidade_produto).toFixed(3)).replace('.',',')
                  this.calculaTotalTributado()
              });
        }
      }
    },
    filialSelect(){
      this.funcFilialSelect()
      if(this.modeloNotaSelect.valor != undefined){
        apiInstance
          .get('/fiscal/Sdf?filial=' + this.filialSelect.fil_codigo + '&dof_codigo=' + this.modeloNotaSelect.valor)
          .then(res => {
            this.serie = res.data.data[0].sdf_serie;
          });
      }
    },
    listaEnderecosCliente(){
      if(this.listaEnderecosCliente.length == 1 && this.disabledEdit == false){
          this.enderecoSelect = this.listaEnderecosCliente[0]
        }
    },
    situacaoDocumento(){
      if(this.situacaoDocumento.codigo == 'C' || this.situacaoDocumento.codigo == 'R'){
        this.liberaInfoAdicionais = false;
      }else{
        this.liberaInfoAdicionais = true;
        this.infoAdicionais = '';
      }
    },
    data_emissao(){
      if(this.data_emissao == null || this.data_emissao == ''){
        this.data_emissao = this.formataData(new Date,'YYYY-MM-DD')
        this.data_ref = parseInt(this.data_emissao[2] + this.data_emissao[3] + this.data_emissao[5] + this.data_emissao[6])
      }else{
        this.data_ref = parseInt(this.data_emissao[2] + this.data_emissao[3] + this.data_emissao[5] + this.data_emissao[6])
      }
    },
    "editedServico.tributado"(){
      if(this.editedServico.tributado == false){
        document.getElementById("aliIcmsID").value = '0,00';
        document.getElementById("aliCofinsID").value = '0,0000';
        document.getElementById("aliPisID").value = '0,0000';
        document.getElementById("baseCalculoID").value = this.editedServico.total;
      }else{
        document.getElementById("aliIcmsID").value = this.impostos.aliIcms;
        document.getElementById("aliCofinsID").value = this.impostos.aliPis;
        document.getElementById("aliPisID").value = this.impostos.aliCofins;
      }
    },
    "editedServico.quantidade"(){
      var valor = Number(this.editedServico.quantidade.replace(/\./g, "").replace(",", "."))
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.num.quantidade = valor;
        this.calculaTotalTributado()
        this.calculaTotal()
      }, 100)
    },
    "editedServico.valorUnitario"(){
      var valor = Number(this.editedServico.valorUnitario.replace(/\./g, "").replace(",", "."))
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
          document.getElementById("valorUnitarioID").value = this.editedServico.valorUnitario;
          this.num.valorUnitario = valor;
          this.calculaTotal()
      }, 100)
    },
    "editedServico.total"(){
      document.getElementById("baseCalculoID").value = this.editedServico.total;
      console.log('total', this.editedServico.total)
    },
    "editedServico.baseCalculo"(){
      this.num.baseCalculo = Number(this.editedServico.baseCalculo.replace(/\./g,"").replace(",", "."));
      this.calculaTotalTributado()
    },
    "editedServico.aliIcms"(){
      this.calculaValIcms()
    },
    "editedServico.aliPis"(){
      this.calculaValPis()
    },
    "editedServico.aliCofins"(){
      this.calculaValCofins()
    },
    "editedServico.valIcms"(){
      this.calculaValIcms()
    },
    "editedServico.valPis"(){
      this.calculaValPis()
    },
    "editedServico.valCofins"(){
      this.calculaValCofins()
    },
    "editedServico.cfop"(){
      if(this.editedServico.cfop.cfop_codigo == '0000'){
        this.editedServico.tributado = false
      }else{
        this.editedServico.tributado = true
      }
    },
    enderecoSelect(){
      if(this.enderecoSelect != null && this.enderecoSelect != ''){
        this.infoCliente.logradouro = this.enderecoSelect.pee_logradouro
        this.infoCliente.cep = this.enderecoSelect.pee_cep
        this.infoCliente.bairro = this.enderecoSelect.pee_bairro
        this.infoCliente.uf = this.enderecoSelect.pee_uf
        this.infoCliente.municipio = this.enderecoSelect.pee_cidade
        this.infoCliente.numero = this.enderecoSelect.pee_numero
        this.infoCliente.complemento = this.enderecoSelect.pee_complemento
      }
    },
    listaServicosSelect(){
      this.calculaTabelaTotais()
    },
    edit(){
      this.limpaNota()
      if(this.edit != 0){
        this.disabledEdit = true
        for(var i = 0 ; i < this.filiais.length ; i++){
          if(this.filiais[i].pessoa.pes_identificador == this.item.nfst_cnpj_emitente){ 
            this.filialSelect = this.filiais[i]
          }
        }
        this.funcFilialSelect()
        for(var i = 0 ; i < this.tiposUtilizacao.length ; i++){
          if(this.tiposUtilizacao[i].codigo == this.item.nfst_fase_ou_tipo_utilizacao){
            this.utilizacaoSelect = this.tiposUtilizacao[i]
          }
        }
        for(var i = 0 ; i < this.tiposCliente.length ; i++){
          if(this.tiposCliente[i].codigo == this.item.nfst_tipo_cliente){
            this.tipoClienteSelect = this.tiposCliente[i]
          }
        }
        for(var i = 0 ; i < this.tiposIsencao.length ; i++){
          if(this.tiposIsencao[i].codigo == this.item.nfst_tipo_isencao){
            this.tipoIsencaoSelect = this.tiposIsencao[i]
          }
        }
        this.data_ref = this.item.nfst_ano_mes_referencia
        this.enviaImpostos.bcICMS = this.item.nfst_bc_icms
        this.codigo = this.item.nfst_codigo
        this.data_emissao = this.item.nfst_data_emissao
        this.enviaImpostos.ICMSDestacado = this.item.nfst_icms_destacado
        this.infoAdicionais = this.item.nfst_informacao_adicional
        this.modeloNotaSelect.codigo = this.item.nfst_modelo
        this.faturaComercial = this.item.nfst_nro_fat_comercial
        this.terminalSelect.peco_telefone = this.item.nfst_nro_terminal_ou_uc
        this.observacao = this.item.nfst_observacao
        this.serie = this.item.nfst_serie
        this.situacaoDocumento = this.item.nfst_situacao_documento

        this.infoCliente.logradouro = this.item.nfst_end_logradouro
        this.infoCliente.numero = this.item.nfst_end_numero
        this.infoCliente.complemento = this.item.nfst_end_complemento
        this.infoCliente.bairro = this.item.nfst_end_bairro
        this.infoCliente.uf = this.item.nfst_end_uf
        this.infoCliente.municipio = this.item.nfst_end_cidade
        this.infoCliente.cep = this.item.nfst_end_cep

        apiInstance
            .get('/personComplete/' + this.item.nfst_fk_pes_codigo)
            .then(res => {
              this.clienteSelect = res.data.data
            });

        var k=0
        apiInstance
          .get('/company/cfop-tax?filial=' + this.filialSelect.fil_codigo)
          .then(res => {
            for(var i = 0 ; i < res.data.data.length ; i++){
              if(res.data.data[i].cfop.length != 0){
                this.tiposCFOP[k] = res.data.data[i].cfop;
                k++;
              }
            }
            for(var i = 0 ; i < this.item.services.length ; i++){
              for(var j = 0 ; j < this.tiposCFOP.length ; j++){
                if(this.tiposCFOP[j].cfop_codigo == this.item.services[i].nfsti_fk_cfop_codigo){
                  this.listaServicosSelect[i].cfop = this.tiposCFOP[j]
                }
              }
              this.item.services[i].nfsti_tributado==1?true:false
            }
          });
          
        for(var i = 0 ; i < this.item.services.length ; i++){
          this.numOrdemFinal += 1
          this.listaServicosSelect[i] = Object.assign({}, this.defaultServico)
          this.listaServicosSelect[i].codigo = this.item.services[i].nfsti_fk_pve_codigo
          //this.listaServicosSelect[i].codReferencia = this.item.services[i].nfsti_cod_referencia
          this.listaServicosSelect[i].codClassificacao = this.item.services[i].nfsti_classificacao
          this.listaServicosSelect[i].descricao = this.item.services[i].nfsti_descricao

          this.listaServicosSelect[i].quantidade = String(this.item.services[i].nfsti_quantidade).replace('.',',')
          this.listaServicosSelect[i].valorUnitario = String(this.item.services[i].nfsti_valor_unitario).replace('.',',')
          this.listaServicosSelect[i].total = String(this.item.services[i].nfsti_valor_total).replace('.',',')
          this.listaServicosSelect[i].baseCalculo = this.item.services[i].nfsti_tributado == 0 ? String(this.item.services[i].nfsti_valor_total).replace('.',',') : String(this.item.services[i].nfsti_bc_icms).replace('.',',')
          this.listaServicosSelect[i].totalTributado = String(this.item.services[i].nfsti_total_com_tributacao).replace('.',',')
          this.listaServicosSelect[i].unidade = this.item.services[i].nfsti_unidade
          this.listaServicosSelect[i].numOrdem = this.item.services[i].nfsti_nro_ordem
          this.listaServicosSelect[i].aliIcms = String(this.item.services[i].nfsti_aliquota_icms).replace('.',',')
          this.listaServicosSelect[i].valIcms = String(this.item.services[i].nfsti_valor_icms).replace('.',',')
          this.listaServicosSelect[i].aliCofins = String(this.item.services[i].nfsti_aliquota_cofins).replace('.',',')
          this.listaServicosSelect[i].valCofins = String(this.item.services[i].nfsti_valor_cofins).replace('.',',')
          this.listaServicosSelect[i].aliPis = String(this.item.services[i].nfsti_aliquota_pis).replace('.',',')
          this.listaServicosSelect[i].valPis = String(this.item.services[i].nfsti_valor_pis).replace('.',',')
          this.listaServicosSelect[i].somaTributos = String((Number(this.item.services[i].nfsti_valor_pis) + Number(this.item.services[i].nfsti_valor_icms) + Number(this.item.services[i].nfsti_valor_cofins)).toFixed(2)).replace('.',',')
          this.listaServicosSelect[i].tributado = this.item.services[i].nfsti_tributado == 1 ? true : false
          this.listaServicosSelect[i].cfop.cfop_codigo = this.item.services[i].nfsti_fk_cfop_codigo
        }
        this.calculaTabelaTotais()
      }else{
        this.disabledEdit = false
        this.clienteSelect = {
          pes_codigo: ''
        }
        this.listaEnderecosCliente = []
        this.tiposCFOP = []
      }
    },
  },
  created () {
      this.initialize()
    },
  directives: {money: VMoney},
  methods: {
    initialize () {
      this.dataHoje = this.formataData(new Date,'YYYY-MM-DD')
      this.data_emissao = this.dataHoje
      apiInstance
        .get('/service')
        .then(res => {
          this.servicos = res.data.data;
        });

      apiInstance
        .get('/company/branch?fil_pessoa=true')
        .then(res => {
          this.filiais = res.data.data;
        if(this.filiais.length == 1){
          this.filialSelect = this.filiais[0]
        }
        });

      apiInstance
        .get('user/me/person')
        .then(res => {
          this.user = res.data.pes_nome;
        });
    },
    calculaTotal() {
        this.editedServico.total = String((Number(this.num.valorUnitario * this.num.quantidade).toFixed(2)).replace(".", ","))
        console.log('valor',this.num.valorUnitario)
        console.log('quantidade',this.num.quantidade)
    },
    abilitaEdicao(){
      this.disabledEdit = !this.disabledEdit
    },
    calculaTotalTributado(){
      if(this.editedServico.tributado == true){
        document.getElementById("aliIcmsID").value = this.editedServico.aliIcms;
        this.num.aliIcms = Number(this.editedServico.aliIcms.replace(/\./g,"").replace(",", "."));

        document.getElementById("aliPisID").value = this.editedServico.aliPis;
        this.num.aliPis = Number(this.editedServico.aliPis.replace(/\./g,"").replace(",", "."));

        document.getElementById("aliCofinsID").value = this.editedServico.aliCofins;
        this.num.aliCofins = Number(this.editedServico.aliCofins.replace(/\./g,"").replace(",", "."));

        document.getElementById("valIcmsID").value = String(Number(this.num.aliIcms/100 * this.num.baseCalculo).toFixed(2).replace('.',','))
        this.num.valIcms = Number(this.editedServico.valIcms.replace(/\./g,"").replace(",", "."));

        document.getElementById("valPisID").value = String(Number(this.num.aliPis/100 * this.num.baseCalculo).toFixed(2).replace('.',','))
        this.num.valPis = Number(this.editedServico.valPis.replace(/\./g,"").replace(",", "."));

        document.getElementById("valCofinsID").value = String(Number(this.num.aliCofins/100 * this.num.baseCalculo).toFixed(2).replace('.',','))
        this.num.valCofins = Number(this.editedServico.valCofins.replace(/\./g,"").replace(",", "."));

        this.num.valIcms = Number(this.editedServico.valIcms.replace(/\./g,"").replace(",", "."))
        this.num.valPis = Number(this.editedServico.valPis.replace(/\./g,"").replace(",", "."))
        this.num.valCofins = Number(this.editedServico.valCofins.replace(/\./g,"").replace(",", "."))

        this.num.somaTributos = this.num.valIcms + this.num.valPis + this.num.valCofins
        this.editedServico.totalTributado = this.editedServico.total
        this.editedServico.somaTributos = String((this.num.somaTributos).toFixed(2).replace(".", ","))
      }else{
        this.editedServico.somaTributos = 0
        this.editedServico.totalTributado = this.editedServico.total
      }
    },
    calculaValIcms(){
      document.getElementById("aliIcmsID").value = this.editedServico.aliIcms;
      this.num.aliIcms = Number(this.editedServico.aliIcms.replace(/\./g,"").replace(",", "."));

      document.getElementById("valIcmsID").value = String(Number(this.num.aliIcms/100 * this.num.baseCalculo).toFixed(2).replace('.',','))
      this.num.valIcms = Number(this.editedServico.valIcms.replace(/\./g,"").replace(",", "."));

      this.num.valIcms = Number(this.editedServico.valIcms.replace(/\./g,"").replace(",", "."))

      this.num.somaTributos = this.num.valIcms + this.num.valPis + this.num.valCofins
      this.editedServico.totalTributado = this.editedServico.total
      this.editedServico.somaTributos = String((this.num.somaTributos).toFixed(2).replace(".", ","))
    },
    calculaValPis(){
      document.getElementById("aliPisID").value = this.editedServico.aliPis;
      this.num.aliPis = Number(this.editedServico.aliPis.replace(/\./g,"").replace(",", "."));

      document.getElementById("valPisID").value = String(Number(this.num.aliPis/100 * this.num.baseCalculo).toFixed(2).replace('.',','))
      this.num.valPis = Number(this.editedServico.valPis.replace(/\./g,"").replace(",", "."));

      this.num.valPis = Number(this.editedServico.valPis.replace(/\./g,"").replace(",", "."))

      this.num.somaTributos = this.num.valIcms + this.num.valPis + this.num.valCofins
      this.editedServico.somaTributos = String((this.num.somaTributos).toFixed(2).replace(".", ","))
    },
    calculaValCofins(){
      document.getElementById("aliCofinsID").value = this.editedServico.aliCofins;
      this.num.aliCofins = Number(this.editedServico.aliCofins.replace(/\./g,"").replace(",", "."));

      document.getElementById("valCofinsID").value = String(Number(this.num.aliCofins/100 * this.num.baseCalculo).toFixed(2).replace('.',','))
      this.num.valCofins = Number(this.editedServico.valCofins.replace(/\./g,"").replace(",", "."));

      this.num.valCofins = Number(this.editedServico.valCofins.replace(/\./g,"").replace(",", "."))

      this.num.somaTributos = this.num.valIcms + this.num.valPis + this.num.valCofins
      this.editedServico.somaTributos = String((this.num.somaTributos).toFixed(2).replace(".", ","))
    },
    calculaTabelaTotais(){
      this.totaisServicos[0].valor = '0,00' //Total de serviços tributados
      this.totaisServicos[1].valor = '0,00' //Outros Valores
      this.totaisServicos[2].valor = '0,00' //Valores isentos
      this.totaisServicos[3].valor = '0,00' //Valor total da nota
      this.totaisServicos[4].valor = '0,00' //Valor total dos tributos
      this.enviaImpostos.bcICMS = '0,00'
      this.enviaImpostos.ICMSDestacado = '0,00'
      this.enviaImpostos.totalSemIcms = '0,00'
      for(var i = 0 ; i < this.listaServicosSelect.length ; i++){
        if(this.listaServicosSelect[i].tributado == false){
          this.totaisServicos[1].valor = String(Number(this.totaisServicos[1].valor.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].total.replace(/\./g,"").replace(',','.'))).replace('.',',')
        }
        if(this.listaServicosSelect[i].total != this.listaServicosSelect[i].baseCalculo){
          this.totaisServicos[2].valor = String(Number(this.totaisServicos[2].valor.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].total.replace(/\./g,"").replace(',','.')) - Number(this.listaServicosSelect[i].baseCalculo.replace(/\./g,"").replace(',','.'))).replace('.',',')
        }
        if(this.listaServicosSelect[i].tributado == true){
          this.totaisServicos[0].valor = String(Number(this.totaisServicos[0].valor.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].totalTributado.replace(/\./g,"").replace(',','.'))).replace('.',',')
          if(this.listaServicosSelect[i].cfop.cfop_codigo != "0000"){
            this.enviaImpostos.bcICMS = String(Number(this.enviaImpostos.bcICMS.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].baseCalculo.replace(/\./g,"").replace(',','.'))).replace('.',',')
            this.enviaImpostos.ICMSDestacado = String(Number(this.enviaImpostos.ICMSDestacado.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].valIcms.replace(/\./g,"").replace(',','.'))).replace('.',',')
          }
        }
        if(this.listaServicosSelect[i].somaTributos != 0){
         this.totaisServicos[4].valor = String(Number(this.totaisServicos[4].valor.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].somaTributos.replace(/\./g,"").replace(',','.'))).replace('.',',')
         }

         this.totaisServicos[3].valor = String(Number(this.totaisServicos[3].valor.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].totalTributado.replace(/\./g,"").replace(',','.'))).replace('.',',')

         this.enviaImpostos.totalSemIcms = String(Number(this.enviaImpostos.totalSemIcms.replace(/\./g,"").replace(',','.')) + Number(this.listaServicosSelect[i].total.replace(/\./g,"").replace(',','.'))).replace('.',',')
      }
      this.totaisServicos[0].valor = String(Number(this.totaisServicos[0].valor.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
      this.totaisServicos[1].valor = String(Number(this.totaisServicos[1].valor.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
      this.totaisServicos[2].valor = String(Number(this.totaisServicos[2].valor.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
      this.totaisServicos[3].valor = String(Number(this.totaisServicos[3].valor.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
      this.totaisServicos[4].valor = String(Number(this.totaisServicos[4].valor.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
      this.enviaImpostos.bcICMS = String(Number(this.enviaImpostos.bcICMS.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
      this.enviaImpostos.ICMSDestacado = String(Number(this.enviaImpostos.ICMSDestacado.replace(/\./g,"").replace(',','.')).toFixed(2)).replace('.',',')
    },
    searchContrato(){
      apiInstance
        .get('/ged/contract?pes_codigo=' + this.infoCliente.codigo + '&filial=' + this.filialSelect.fil_codigo)
        .then(res => {
          this.listaContratos = res.data.data;
          if(this.listaContratos.length == 1){
          this.contratoSelect = this.listaContratos[0]
        }
        });
    },
    valorMonetario(valor){
      return Number(valor).toFixed(2);
    },
    editItem (item) {
      console.log(item)
      this.edicaoServico = true
      this.deleteItemEdit(item);
      this.editedServico.codigo = item.codigo
      //this.editedServico.codReferencia = item.codReferencia
      this.editedServico.codClassificacao = item.codClassificacao
      this.editedServico.descricao = item.descricao
      this.editedServico.cfop = item.cfop
      this.editedServico.unidade = item.unidade
      this.editedServico.somaTributos = item.somaTributos
      this.editedServico.tributado = item.tributado
      this.editedServico.numOrdem = this.numOrdemFinal
      document.getElementById("baseCalculoID").value = item.baseCalculo;
      document.getElementById("valorUnitarioID").value = item.valorUnitario;
      document.getElementById("quantidadeID").value = item.quantidade;
      document.getElementById("totalID").value = item.total;
      document.getElementById("aliPisID").value = item.aliPis;
      document.getElementById("aliCofinsID").value = item.aliCofins;
      document.getElementById("aliIcmsID").value = item.aliIcms;
      document.getElementById("valPisID").value = item.valPis;
      document.getElementById("valCofinsID").value = item.valCofins;
      document.getElementById("valIcmsID").value = item.valIcms;
    },

    deleteItem (item) {
      this.editedIndex = this.listaServicosSelect.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemEdit (item) {
      this.editedIndex = this.listaServicosSelect.indexOf(item)
      this.listaServicosSelect.splice(this.editedIndex, 1)
      this.numOrdemFinal -= 1
      for(var i=this.editedIndex ; i <= this.numOrdemFinal - 2; i++){
        this.listaServicosSelect[i].numOrdem -= 1
      }
      this.editedIndex = -1
    },

    deleteItemConfirm () {
      this.listaServicosSelect.splice(this.editedIndex, 1)
      this.numOrdemFinal -= 1
      for(var i=this.editedIndex ; i <= this.numOrdemFinal - 2; i++){
        this.listaServicosSelect[i].numOrdem -= 1
      }
      this.closeDelete()
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        //this.editedServico = Object.assign({}, this.defaultServico)
        this.editedIndex = -1
      })
    },
    completaEditedServico(){
      console.log('select', this.servicoSelect)
      this.editedServico.codigo = this.servicoSelect.pro_codigo
      //this.editedServico.codReferencia = this.servicoSelect.atributos.length!=0?this.servicoSelect.atributos[0].ppsa_codigo:''
      this.editedServico.codClassificacao = this.servicoSelect.atributos.length!=0?this.servicoSelect.atributos[0].ppsa_valor:''
      this.editedServico.descricao = this.servicoSelect.pro_descricao
      this.editedServico.unidade = this.servicoSelect.variedade[0].product_variety_pack[0].measurement_unit.unm_sigla
      this.editedServico.numOrdem = this.numOrdemFinal
      document.getElementById("valorUnitarioID").value = String(Number(this.servicoSelect.variedade[0].prv_valor_venda).toFixed(2)).replace('.',',')
      if(this.editedServico.tributado == true){   
        document.getElementById("aliPisID").value = this.impostos.aliPis.replace('.',',');
        document.getElementById("aliCofinsID").value = this.impostos.aliCofins.replace('.',',');
        document.getElementById("aliIcmsID").value = this.impostos.aliIcms.replace('.',',');
      }
    },
    funcFilialSelect(){
      console.log('funcFilialSelect')
      if(this.filialSelect != null){
        var j = 0;
        var tiposCFOP = []
        apiInstance
          .get('/company/cfop-tax?filial=' + this.filialSelect.fil_codigo)
          .then(res => {
            for(var i = 0 ; i < res.data.data.length ; i++){
              if(res.data.data[i].cfop.length != 0){
                tiposCFOP[j] = res.data.data[i].cfop;
                j++;
              }
              if(res.data.data[i].imposto.imp_codigo == 'PIS'){
                this.impostos.aliPis =  res.data.data[i].fist_aliquota.length==4?res.data.data[i].fist_aliquota+'00':res.data.data[i].fist_aliquota;
              }
              if(res.data.data[i].imposto.imp_codigo == 'ICMS'){
                this.impostos.aliIcms = res.data.data[i].fist_aliquota;
              }
              if(res.data.data[i].imposto.imp_codigo == 'COFINS'){
                this.impostos.aliCofins = res.data.data[i].fist_aliquota.length==4?res.data.data[i].fist_aliquota+'00':res.data.data[i].fist_aliquota;
              }
            }
          this.tiposCFOP = tiposCFOP
          });
      }
    },
    getColor(item){
      if(item.codClassificacao == '' || item.cfop == '' || item.codigo == '' || item.descricao == '' || item.unidade == '' || item.valorUnitario == '' || item.quantidade == '' || item.total == ''){
       return 'background-color:rgb(243, 208, 208)'
      }
    },
    addItem() {
      if(this.editedServico.cfop.cfop_codigo == undefined){
        swal.fire(
          '',
          'Selecione um CFOP!',
          'warning',
      );
      return
      }
      if(this.editedServico.quantidade == '0,000'){
        swal.fire(
          '',
          'Informe uma quantidade!',
          'warning',
      );
      return
      }
      this.numOrdemFinal += 1
      this.listaServicosSelect.push(this.editedServico)
      this.edicaoServico = false
      this.limpaItem()
    },
    limpaItem(){
      this.editedServico = Object.assign({}, this.defaultServico)
      this.limpaServico += 1;
      this.editedServico.cfop = {};
      
      if(document.getElementById("valorUnitarioID")!=null){
        document.getElementById("valorUnitarioID").value = '0,00';
        document.getElementById("quantidadeID").value = '0,00';
        document.getElementById("totalID").value = '0,00';
        document.getElementById("aliPisID").value = '0,00';
        document.getElementById("aliCofinsID").value = '0,00';
        document.getElementById("aliIcmsID").value = '0,00';
        document.getElementById("valPisID").value = '0,00';
        document.getElementById("valCofinsID").value = '0,00';
        document.getElementById("valIcmsID").value = '0,00';
        document.getElementById("baseCalculoID").value = '0,00';
      }
      
      this.edicaoServico = false
    },
    limpaNota(){
      this.limpaCliente += 1;
      this.limpaItem()
      this.infoCliente = Object.assign({}, this.defaultCliente)
      this.listaServicosSelect = []
      this.filialSelect = null
      this.utilizacaoSelect = null
      this.tipoClienteSelect = null
      this.tipoIsencaoSelect = null
      this.modeloNotaSelect = {}
      this.contratoSelect = null
      this.enviaImpostos.totalSemIcms = '0,00'
      this.enviaImpostos.ICMSDestacado = '0,00'
      this.enviaImpostos.bcICMS = '0,00'
      this.faturaComercial = ''
      this.observacao = ''
      this.serie = ''
      this.dataHoje = this.formataData(new Date,'YYYY-MM-DD')
      this.data_emissao = this.dataHoje
    },
    funcSalvaServicos() {
      for(var i=0; i<this.listaServicosSelect.length; i++){
        this.salvaServicos[i] = {
          nfsti_nro_ordem : this.listaServicosSelect[i].numOrdem,
          nfsti_classificacao : this.listaServicosSelect[i].codClassificacao != '' ? Number(this.listaServicosSelect[i].codClassificacao) : 1,
          //nfsti_cod_referencia : this.listaServicosSelect[i].codReferencia != '' ? this.listaServicosSelect[i].codReferencia : '1',
          nfsti_fk_cfop_codigo : this.listaServicosSelect[i].cfop.cfop_codigo,
          nfsti_fk_pve_codigo : this.listaServicosSelect[i].codigo,
          nfsti_descricao : this.listaServicosSelect[i].descricao,
          nfsti_unidade : this.listaServicosSelect[i].unidade,
          nfsti_valor_unitario : Number(this.listaServicosSelect[i].valorUnitario.replace(/\./g,"").replace(',','.')),
          nfsti_quantidade : Number(this.listaServicosSelect[i].quantidade.replace(/\./g,"").replace(',','.')),
          nfsti_valor_total : Number(this.listaServicosSelect[i].total.replace(/\./g,"").replace(',','.')),
          nfsti_aliquota_icms : Number(this.listaServicosSelect[i].aliIcms.replace(/\./g,"").replace(',','.')),
          nfsti_valor_icms : Number(this.listaServicosSelect[i].valIcms.replace(/\./g,"").replace(',','.')),
          nfsti_aliquota_pis : Number(this.listaServicosSelect[i].aliPis.replace(/\./g,"").replace(',','.')),
          nfsti_valor_pis : Number(this.listaServicosSelect[i].valPis.replace(/\./g,"").replace(',','.')),
          nfsti_aliquota_cofins : Number(this.listaServicosSelect[i].aliCofins.replace(/\./g,"").replace(',','.')),
          nfsti_valor_cofins : Number(this.listaServicosSelect[i].valCofins.replace(/\./g,"").replace(',','.')),
          nfsti_total_com_tributacao : Number(this.listaServicosSelect[i].totalTributado.replace(/\./g,"").replace(',','.')),
          nfsti_tributado : this.listaServicosSelect[i].tributado==true ? 1 : 0,
          nfsti_op_isentas : Number(this.listaServicosSelect[i].total.replace(/\./g,"").replace(',','.')) - Number(this.listaServicosSelect[i].baseCalculo.replace(/\./g,"").replace(',','.')),
          nfsti_outros_valores : this.listaServicosSelect[i].tributado==true ? 0 : Number(this.listaServicosSelect[i].total.replace(/\./g,"").replace(',','.')),
          nfsti_bc_icms : this.listaServicosSelect[i].tributado==true ? Number(this.listaServicosSelect[i].baseCalculo.replace(/\./g,"").replace(',','.')) : 0,
        }
      }
    },
    handleOk(bvModalEvt) {
      if(this.disabledEdit == false){
        bvModalEvt.preventDefault();
        if (this.filialSelect.fil_codigo == undefined) {
          swal.fire(
              '',
              'Por favor, informe uma filial para continuar!',
              'warning',
          );
        }else if (this.modeloNotaSelect.codigo == undefined) {
          swal.fire(
              '',
              'Por favor, informe o modelo de nota para continuar!',
              'warning',
          );
        }else if (this.utilizacaoSelect == null) {
          swal.fire(
              '',
              'Por favor, informe o tipo de utilização para continuar!',
              'warning',
          );
        }else if (this.tipoClienteSelect == null) {
          swal.fire(
              '',
              'Por favor, informe o tipo de cliente para continuar!',
              'warning',
          );
        }else if (this.tipoIsencaoSelect == null) {
          swal.fire(
              '',
              'Por favor, informe o tipo de isenção para continuar!',
              'warning',
          );
        }else if (this.infoCliente.codigo == '') {
          swal.fire(
              '',
              'Por favor, informe o cliente para continuar!',
              'warning',
          );
        }else if (this.terminalSelect.peco_telefone == undefined && this.modeloNotaSelect.codigo != 22) {
          swal.fire(
              '',
              'Por favor, informe o número do terminal para continuar!',
              'warning',
          );
        }else if (this.infoCliente.id == '') {
          swal.fire(
              '',
              'Por favor, informe o ID do consumidor para continuar!',
              'warning',
          );
        }else if (this.faturaComercial == '') {
          swal.fire(
              '',
              'Por favor, informe o número da fatura comercial para continuar!',
              'warning',
          );
        }else if (this.infoCliente.telefone == '') {
          swal.fire(
              '',
              'Por favor, informe o número do telefone do cliente para continuar!',
              'warning',
          );
        }else if (this.listaServicosSelect[0] == null) {
          swal.fire(
              '',
              'Por favor, adicione ao menos um serviço para continuar!',
              'warning',
          );
        }else {
          this.handleSubmit();
        }
      }
    },
    handleSubmit() {
      this.funcSalvaServicos();
      const obj = 
        {
          "nfst_fk_pes_codigo" : this.infoCliente.codigo,
          "nfst_cpf_cnpj" : this.infoCliente.identificador,
          "nfst_ie" : this.infoCliente.ie != '' ? this.infoCliente.ie : "ISENTO",
          "nfst_razao_social" : this.infoCliente.nome,
          "nfst_telefone_contato" : this.infoCliente.telefone,
          "nfst_end_cep" : this.infoCliente.cep,
          "nfst_end_logradouro" : this.infoCliente.logradouro,
          "nfst_end_numero" : this.infoCliente.numero,
          "nfst_end_bairro" : this.infoCliente.bairro,
          "nfst_end_complemento" : this.infoCliente.complemento,
          "nfst_end_cidade" : this.infoCliente.municipio,
          "nfst_end_uf" : this.infoCliente.uf,
          "nfst_classe_consumo" : 0,
          "nfst_cnpj_emitente" : this.filialSelect.pessoa.pes_identificador,
          "nfst_fase_ou_tipo_utilizacao" : this.utilizacaoSelect.codigo,
          "nfst_grupo_tensao" : '00',
          "nfst_cod_id_consumidor_assinante" : this.infoCliente.id,
          "nfst_data_emissao" : this.formataData(this.data_emissao, 'YYYYMMDD'),
          "nfst_modelo" : this.modeloNotaSelect.codigo,
          "nfst_serie" : this.serie,
          "nfst_valor_total" : Number(this.enviaImpostos.totalSemIcms.replace(/\./g,"").replace(',','.')),
          "nfst_bc_icms" : Number(this.enviaImpostos.bcICMS.replace(/\./g,"").replace(',','.')),
          "nfst_icms_destacado" : Number(this.enviaImpostos.ICMSDestacado.replace(/\./g,"").replace(',','.')),
          "nfst_op_isentas" : Number(this.totaisServicos[2].valor.replace(/\./g,"").replace(',','.')),
          "nfst_outros_valores" : Number(this.totaisServicos[1].valor.replace(/\./g,"").replace(',','.')),
          "nfst_situacao_documento" : this.situacaoDocumento,
          "nfst_informacao_adicional" : this.infoAdicionais,
          "nfst_ano_mes_referencia" : this.data_ref,
          "nfst_nro_terminal_ou_uc" : this.terminalSelect.peco_telefone, 
          "nfst_tipo_pessoa" : this.infoCliente.tipo,
          "nfst_tipo_cliente" : this.tipoClienteSelect.codigo,
          "nfst_tipo_isencao" : this.tipoIsencaoSelect.codigo,
          "nfst_subclasse_consumo" : '00',
          "nfst_nro_terminal_principal" : this.terminalSelect.peco_telefone,
          "nfst_nro_fat_comercial" : this.faturaComercial,
          "nfst_valor_total_fat_comercial" : Number(this.totaisServicos[3].valor.replace(/\./g,"").replace(',','.')),
          "nfst_uf_terminal" : this.ufHabilitacao.sigla,
          "nfst_data_leitura_anterior" : "00000000",
          "nfst_data_leitura_atual" : "00000000",
          "nfst_observacao" : this.observacao,
          "nfst_usu_insert" : this.user,
          "servicos": this.salvaServicos
        }
        swal.fire({
          title: '',
          html:
            '<span class="mdi mdi-spin mdi-loading"></span>' +
            ' Salvando...',
          showConfirmButton: false,
        });
      if (this.edit) {
        apiInstance
          .put('/fiscal/Nfst/'+ this.codigo, obj)
          .then((response) => {
            swal
                .fire({
                  title: '',
                  text: 'NF atualizada com sucesso!',
                  icon: 'success',
                  timer: 2000,
                })
                .then(willDelete => {
                  if (willDelete) {
                    location.reload();
                  }
                });
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        apiInstance
          .post('/fiscal/Nfst', obj)
          .then((response) => {
            swal
                .fire({
                  title: '',
                  text: 'NF cadastrada com sucesso!',
                  icon: 'success',
                  timer: 2000,
                })
                .then(willDelete => {
                  if (willDelete) {
                    location.reload();
                  }
                });
          })
          .catch((error) => {
            console.log(error);
          })
        };
    },
    handleCancel(){
    },
    formataData(data, formato){
      return moment(String(data)).format(formato)
    },
    formataValor(valor){
      return  String(valor);
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
  },
};
</script>

<style scoped>
#Modal {
}

*{
  padding: 1px 5px;
}

.modal__title {
  text-align: rigth;
  font-size: 20px;
}

.table-padding {
  padding-bottom: 1em;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.btn-table-inner {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
}

.btn-table-inner-delete {
  color: #ffffff;
  border: solid 1px #dc3545;
  border-radius: 0.25rem;
  background: #dc3545;
}

.btn-table-inner-delete:hover {
  color: #dc3545;
  border-color: #dc3545;
  background-color: #ffffff;
  cursor: pointer;
}

.icon-table-inner {
  fill: currentColor;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}

.btn-push-option {
  display: flex;
  align-items: flex-end;
  justify-items: flex-end;
}

.formBordas{
  border: solid medium rgba(0, 0, 0, 0.500);
  padding: 10px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.botaoAlterar{
  margin-top:37px;
}

.checkbox{
  margin:auto;
  padding: 10px 0px 0px 40px;
}

.align-right{
  text-align: right;
}

.campoObrigatorio >>> div.v-input__slot{
  padding: 0px 0px 0px 0px !important;
}

.align-center{
  text-align: center;
}

.tam{
  width: 200px;
}

.box{
  border: solid 1px  rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px 20px 30px 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.nameBox{
  background-color: #ffffff;
}

.addServico{
  font-size: 68px;
  margin-top: 24px;
  color: rgb(68, 68, 68);
}

.addServico:hover{
  color: rgb(168, 168, 168);
}

.checkboxTributado{
  margin-top:40px;
}

.right-input >>> input {
  text-align: right;
}

.obrigatorio.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
padding: 0px 12px 0px 0px!important;
}

.img-pd-0{
  padding: 0px
}
</style>
