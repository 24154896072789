<template>
  <div class="no-padding">
    <label>{{topLabel}}</label>

      <v-text-field
      :class="classe"
      solo
      :type="type"
      :disabled="disabled"
      v-model="model"
      :label="label"
      :reverse="reverse"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      hide-details
      return-object
      @keypress="onlyNumber==true?filter(event):''"
      >

        <template v-if="CampoObrigatorio" v-slot:prepend-inner>
          <img
            style="vertical-align:top;"
            width="20"
            height="49"
            alt=""
            src="../../assets/faixaCampoObrigatorio.png"
          />
        </template>

        

      </v-text-field>

  </div>
</template>

<script>

export default {
  name: 'InterfaceText',
  props: {
    data: {
      default: '',
    },
    onlyNumber: {
      default: false,
    },
    topLabel: {
      default: '',
    },
    label: {
      default: '',
    },
    counter: {
      default: false,
    },
    type: {
      default: 'text',
    },
    placeholder: {},
    disabled: {
      default: false,
    },
    CampoObrigatorio:{
      default: false,
    },
    classe:{
      default: '',
    },
    min:{
      default: '',
    },
    max:{
      default: '',
    },
    onkeyup:{
      default: '',
    },
    maxlength:{
      default: '',
    },
    reverse:{
      default: false
    },
    decimalFixo:{
      default: false
    },

  },
  data() {
    return {
      emitData: [],
      model: '',
      event: '',
    };
  },
  watch: {
    data(){
      this.model = this.data
    },
    model(){
      this.$emit('update:value', this.model);
    }
  },
  methods:{
    filter: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};


</script>

<style>
.aling-left{
  text-align: left;
}

.v-list-item__title{
  text-align: left;
}

.disabled.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
  background-color: rgb(223, 223, 223) !important;
}

.obrigatorio.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
padding: 0px 12px 0px 0px!important;
}
</style>