<template>
  <b-row>

    <b-col cols="12" sm="3" md="3" class="no-padding">
      <label>Serviço:</label>
      <v-text-field
      :class="classe"
      solo
      type="number"
      :disabled="disabled"
      v-model="dataCodigo"
      :label="labelCodigo"
      hide-details
      return-object
      >
        <template v-slot:prepend-inner>
          <img
            style="vertical-align:top;"
            width="20"
            height="49"
            alt=""
            src="../../assets/faixaCampoObrigatorio.png"
          />
        </template>
      </v-text-field>
    </b-col>

    <b-col cols="12" sm="9" md="9" class="no-padding">
      <label>⠀</label>
      <v-autocomplete
      :class="classe"
      solo
      persistent-hint
      :disabled="disabled"
      v-model="data"
      :items="entries"
      :search-input.sync="search"
      auto-select-first
      hide-no-data
      hide-details
      hide-selected
      item-text="pro_descricao"
      item-value="pro_codigo"
      :label="labelNome"
      :placeholder="placeholder"
      return-object
      >
        <template v-slot:prepend-inner>
          <img
            style="vertical-align:top;"
            width="20"
            height="49"
            alt=""
            src="../../assets/faixaCampoObrigatorio.png"
          />
        </template>
      </v-autocomplete>
    </b-col>

  </b-row>

</template>

<script>
import apiInstance from "../../services/api/config";

export default {
  name: 'ServiceSearch',
  props: {
    placeholder: {},
    disabled: {
      default: false,
    },
    labelNome: {
      default: "Nome do serviço",
    },
    labelCodigo: {
      default: "Código",
    },
    limpaDados: {
      default: 0,
    },
    classe: {
      default: '',
    },
  },
  data() {
    return {
      data: '',
      dataCodigo: '',
      entries: [],
      search: null,
      noSearch: false,
    };
  },
  methods: {
    getServicos() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        if (this.search.length >= 3) {
        apiInstance
            .get('/service?pro_descricao=' + this.search)
            .then(res => {
              this.entries = res.data.data;
            });
        }
      }, 800)
    },
    getServicosCodigo() {
      if(this.dataCodigo != null && this.dataCodigo != '' && this.dataCodigo.length >= 2){
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          apiInstance
            .get('/service/' + this.dataCodigo)
            .then(res => {
              this.noSearch = true;
              if(res.data.data[0] != undefined){
                this.data = res.data.data[0];
              }else{
                this.data = [];
              }
            });
        }, 500)
      }
    },
  },

  computed: {},

  watch: {
    async search() {
      if(this.search != null){
        if(this.search.length == 3){
          this.noSearch = false;
        }
        if(this.noSearch == false){
            await this.getServicos();
        }
      }else{
        this.data = ''
      }
    },
    dataCodigo(){
      if(this.dataCodigo != null){
        this.noSearch = false;
        this.getServicosCodigo();
      }
    },
    data(){
      if(this.data != null && this.data != ''){
        if(this.data.length != 0){
          this.dataCodigo = this.data.pro_codigo
        }
      }
      this.$emit('update:value', this.data);
    },
    limpaDados(){
      this.dataCodigo = null
      this.entries = []
      this.data = {
        pro_descricao: ''
      }
    }
  },
};
</script>
