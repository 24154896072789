import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
   theme: {
     themes: {
       light: {
         primary: '#c31d1f',
         secondary: '#b0bec5',
         accent: '#8c9eff',
         error: '#b71c1c',
       },
     },
   },
 })

export default vuetify;
