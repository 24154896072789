<template>
  <div id="compModal">
    <b-modal
      :id="id"
      ok-only
      okVariant="danger"
      @ok="handleOk"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      ref="modal"
      size="xl"
      :title="
        'Ordem de produção ' +
          item.op_codigo +
          ' - Status: ' +
          item.status[0].st_descricao
      "
    >
      <template>
        <span>
          <b>Produto: </b> {{ item.op_fk_pve_final }} -
          {{
            item.finished_product.product_variety.product_mobile.pro_descricao
          }}
          <br />
          <b>Unidade de medida: </b>
          {{ item.finished_product.measurement_unit.unm_descricao }}<br />
          <b>Quantidade prevista: </b> {{ item.op_quantidade }} <br />
          <span v-show="item.status[0].st_descricao == 'Finalizado'"
            ><b>Quantidade realizada: </b> {{ item.op_quantidade_produzida
            }}<br
          /></span>
          <!--<b>Custo médio (R$): </b> <br>
            <b>Custo médio recalculado (R$): </b> <br>-->
          <span v-show="item.op_observacao != null">
            <b>Observação: </b> {{ item.op_observacao }}<br
          /></span>
        </span>

        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemComp"
          class="elevation-1"
          :loading="loading"
          loading-text="Carregando..."
        >
          <template v-slot:item.opi_quantidade_inicial="{item}">
            <div style="text-align:right">
              <span>{{ item.opi_quantidade_inicial }}</span>
            </div>
          </template>

          <template
            v-slot:item.feedstock.product_variety.prv_valor_compra="{item}"
          >
            <div style="text-align:right">
              <span>{{
                valorMonetario(
                  item.opi_valor_inicial / item.opi_quantidade_inicial,
                )
              }}</span>
            </div>
          </template>

          <template v-slot:item.opi_valor_inicial="{item}">
            <div style="text-align:right">
              <span>{{ valorMonetario(item.opi_valor_inicial) }}</span>
            </div>
          </template>

          <template v-slot:item.opi_controla_estoque="{item}">
            <div>
              <span v-if="item.opi_controla_estoque == 0">Não</span>
              <span v-if="item.opi_controla_estoque == 1">Sim</span>
            </div>
          </template>
          <template v-if="total" v-slot:body.append>
            <tr>
              <td colspan="5" style="text-align:right">
                <span>Custo de produção (R$):</span>
              </td>
              <td colspan="1" style="text-align:right">
                <span>{{ valorMonetario(totalValue) }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </b-modal>
  </div>
</template>

<script>
import apiInstance from '../../services/api/config';

export default {
  name: 'CompModal',
  props: ['id', 'idEdit', 'item', 'limpaDados'],
  data() {
    return {
      loading: true,
      total: true,
      itemComp: [],
      headers: [
        {
          text: 'Cód.Produto',
          value: 'feedstock.product_variety.product_mobile.pro_codigo',
          width: '11%',
        },
        {
          text: 'Descrição',
          value: 'feedstock.product_variety.product_mobile.pro_descricao',
        },
        {
          text: 'Unidade de medida',
          value: 'feedstock.measurement_unit.unm_descricao',
        },
        {
          text: 'Quantidade',
          value: 'opi_quantidade_inicial',
          align: 'end',
          width: '11%',
        },
        {
          text: 'Custo médio (R$)',
          value: 'feedstock.product_variety.prv_valor_compra',
          align: 'end',
          width: '11%',
        },
        {
          text: 'Total (R$)',
          value: 'opi_valor_inicial',
          align: 'end',
          width: '11%',
        },
        {text: 'Controla estoque', value: 'opi_controla_estoque'},
      ],
    };
  },
  computed: {
    totalValue() {
      let total = 0;
      if (this.itemComp[0] != null) {
        for (let i = 0; i < this.itemComp.length; i++) {
          total += parseFloat(this.itemComp[i].opi_valor_inicial);
        }
      }
      return total.toFixed(2);
    },
  },
  watch: {
    item() {
      apiInstance.get('/production-order/' + this.item.op_codigo).then(res => {
        this.itemComp = res.data.items;
        this.loading = false;
      });
    },
    limpaDados() {
      this.handleOk();
    },
  },
  methods: {
    valorMonetario(valor) {
      return Number(valor).toFixed(2);
    },
    handleOk() {
      this.loading = true;
      this.itemComp = [];
    },
  },
};
</script>

<style scoped>
input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.formBordas {
  border: solid medium rgba(0, 0, 0, 0.5);
  padding: 10px;
}
</style>
