<template>
  <div id="Collapse">
    <b-button
      class="menu-collapse-title"
      @click="collapse"
      v-b-toggle="removeSpace(title)"
    >
      <div class="align-internal-collapse">
        <slot />
        <div class="align-text-collapse">
          <b>{{ title }}</b>
        </div>
        <b-icon-chevron-down class="menu-collapse-icon" v-if="changeIcon" />
        <b-icon-chevron-up class="menu-collapse-icon" v-else />
      </div>
    </b-button>
    <b-collapse :id="removeSpace(title)">
      <div :key="button.text" v-for="button in buttons">
        <Button :route="button.route" :text="button.text" />
      </div>
    </b-collapse>
    <!--        <hr>-->
  </div>
</template>

<script>
import Button from '@/components/index/Button';

export default {
  name: 'Collapse',
  components: {
    Button,
  },
  props: {
    title: String,
    buttons: Array,
  },
  data() {
    return {
      changeIcon: false,
    };
  },
  mounted() {
  },
  methods: {
    collapse() {
      this.changeIcon = !this.changeIcon;
    },
    removeSpace(string) {
      return string.replace(/\s/g, '');
    },
  },
};
</script>

<style scoped>
.align-internal-collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.align-text-collapse {
  text-align: left;
  min-width: 160px;
}

#Collapse {
}

hr {
  margin: 0.1em 0 0 0;
  background-color: #6d6d6c;
  height: 1px;
  width: 100%;
  transition: font-size 1s;
}

b {
  padding-left: 0.2em;
}

.menu-collapse-title {
  text-align: center;
  font-size: 14px;
  color: #6d6d6c;
  text-transform: uppercase;
  background: none;
  border: none;
  width: 100%;
}

.menu-collapse-title:hover {
  color: #c31d1f;
}

.menu-collapse-title:focus {
  border: none;
  box-shadow: none;
}

.menu-collapse-title:active {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  color: #c31d1f !important;
}

.menu-collapse-icon {
  height: 14px;
  stroke: currentColor;
  stroke-width: 0.1em;
}
</style>
