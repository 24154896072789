<template>
  <div id="Title">
    <h1>{{ title }}</h1>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: String,
  },
};
</script>

<style scoped>
#Title {
  margin: 1em 0 3em 0;
}
h1 {
  font-size: 32px;
  padding-top: 0.5em;
  text-align: left;
}
hr {
  background-color: #ced4da;
  border: none;
  height: 1px;
  width: 100%;
}
@media print {
  .on-print {
    display: none;
  }
}
</style>
