<template>
  <div class="no-padding">
    <label>{{topLabel}}</label>
    <v-select
    :class="classe"
    solo
    auto-select-first
    :type="type"
    :disabled="disabled"
    v-model="model"
    :items="entries"
    hide-no-data
    hide-selected
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    hide-details
    return-object
    >
    <template v-if="CampoObrigatorio" v-slot:prepend-inner>
        <img
          style="vertical-align:top;"
          width="20"
          height="49"
          alt=""
          src="../../assets/faixaCampoObrigatorio.png"
        />
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  name: 'InterfaceSelect',
  props: {
    topLabel: {
      default: '',
    },
    label: {
      default: '--SELECIONE--',
    },
    type: {
      default: 'text',
    },
    classe: {
      default: '',
    },
    entries: {
        type: Array,
        default() {
            return []
        }
    },
    disabled: {
      default: false,
    },
    CampoObrigatorio:{
      default: false,
    },
    itemText:{
      default: '',
    },
    itemValue:{
      default: '',
    },
    data:{
      default: '',
    },
  },
  data() {
    return {
      model: this.data,
      emitData: [],
    };
  },
  watch: {
    data() {
      this.model = this.data
    },
    model(){
      this.$emit('update:value', this.model);
    }
  },
};
</script>

<style>
.aling-left{
  text-align: left;
}
.v-list-item__title{
  text-align: left;
}

</style>