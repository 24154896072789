<template>
  <div id="Modal">
    <b-modal
      :id="id"
      @hidden="resetModal"
      @ok="handleOk"
      @show="resetModal"
      @hide="$emit('reset-modal')"
      cancelTitle="Cancelar"
      cancelVariant="outline-secondary"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      okTitle="Salvar"
      okVariant="dark"
      ref="modal"
      size="xl"
      title="Campos"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
        <b-form-group :state="stateLabel" label="Nome do campo">
          <b-form-input :state="stateLabel" required v-model="label" />
        </b-form-group>

        <b-form-group :state="stateType" label="Tipo do campo">
          <b-form-select
            :state="stateType"
            :options="typeOptions"
            required
            v-model="type"
          />
        </b-form-group>

        <b-form-group v-if="type === 2 || type === 5 || type === 7" inline>
          <b-row>
            <b-col cols="12" lg="5">
              <label>Nome da Opção</label>
              <b-form-input
                :state="options.length > 0 ? null : stateOptionLabel"
                v-model="optionLabel"
              />
            </b-col>
            <b-col cols="12" lg="5">
              <label>Valor da Opção</label>
              <b-form-input
                :state="options.length > 0 ? null : stateOptionValue"
                v-model="optionValue"
              />
            </b-col>
            <b-col class="btn-push-option mt-3 mt-lg-0" cols="12" md="2">
              <b-button
                :disabled="optionLabel === '' || optionValue === ''"
                @click="pushOptions(optionLabel, optionValue)"
                class="w-100"
                variant="dark"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="options.length > 0">
            <b-col cols="12" class="mt-3">
              <b-table striped :items="options" :fields="fieldsOptions">
                <template v-slot:cell(label)="row">
                  {{ row.item.label }}
                </template>
                <template v-slot:cell(value)="row">
                  {{ row.item.value }}
                </template>
                <template v-slot:cell(action)="row">
                  <div
                    v-on:click="deleteOption(row.item)"
                    class="btn-table-inner btn-table-inner-delete"
                  >
                    <b-icon-trash class="icon-table-inner" />
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox id="flag-justificativa" v-model="justification">
            Incluir campo de justificativa
          </b-form-checkbox>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import {FormService} from '../../services';

export default {
  name: 'Modal',
  props: ['id', 'idEdit'],
  data() {
    return {
      justification: false,
      label: '',
      type: '',
      optionLabel: '',
      optionValue: '',
      options: [],
      fieldsOptions: [
        {key: 'label', label: 'Opção', sortable: true, class: 'text-center'},
        {key: 'value', label: 'Valor', sortable: true, class: 'text-center'},
        {
          key: 'action',
          label: 'Remover',
          sortable: false,
          class: 'text-center',
        },
      ],
      typeOptions: [
        {text: 'Assinatura', value: 1},
        {text: 'Caixa de seleção', value: 2},
        {text: 'Imagens', value: 3},
        {text: 'Quantidade', value: 4},
        {text: 'Múltipla escolha', value: 5},
        {text: 'Valor', value: 6},
        {text: 'Escolha única', value: 7},
        {text: 'Verdadeiro ou Falso', value: 8},
        {text: 'Texto', value: 9},
      ],
    };
  },
  watch: {
    idEdit() {
      if (this.idEdit !== undefined && this.idEdit !== null) {
        FormService.getDetailedField(this.idEdit).then(res => {
          this.label = res.data.cam_label;
          this.type = res.data.cam_tipo;
          this.justification = res.data.cam_justificativa;
          this.options =
            res.data.cam_opcoes !== null ? res.data.cam_opcoes : [];
        });
      }
    },
  },
  computed: {
    stateLabel() {
      return this.label.length >= 1;
    },
    stateType() {
      return this.type !== '';
    },
    stateOptionLabel() {
      if (this.type === 2 || this.type === 5 || this.type === 7) {
        return this.options.length > 0;
      }
      return true;
    },
    stateOptionValue() {
      if (this.type === 2 || this.type === 5 || this.type === 7) {
        return this.options.length > 0;
      }
      return true;
    },
  },
  methods: {
    deleteOption(item) {
      const index = this.options.indexOf(item);
      this.options.splice(index, 1);
    },
    pushOptions(label, value) {
      this.optionLabel = '';
      this.optionValue = '';
      this.options.push({label: label, value: value});
    },
    resetModal() {
      this.label = '';
      this.type = '';
      this.optionLabel = '';
      this.optionValue = '';
      this.options = [];
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (
        (this.type === 2 || this.type === 5 || this.type === 7) &&
        this.options.length === 0
      ) {
        swal.fire(
          '',
          'Por favor, adicione ao menos duas opções para continuar',
          'warning',
        );
      } else {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      if (!this.stateLabel || !this.stateType || !this.stateOptionLabel) {
        return;
      }
      const obj = {
        cam_justificativa: this.justification,
        cam_label: this.label,
        cam_tipo: this.type,
        cam_opcoes:
          this.type === 2 || this.type === 5 || this.type === 7
            ? this.options
            : undefined,
      };

      if (this.idEdit !== undefined && this.idEdit !== null) {
        FormService.updateField(this.idEdit, obj).then(() => {
          swal
            .fire({
              title: '',
              text: 'Campo atualizado com sucesso !',
              icon: 'success',
            })
            .then(willDelete => {
              if (willDelete) {
                location.reload();
              }
            });
        });
      } else {
        FormService.newField(obj).then(() => {
          swal
            .fire({
              title: '',
              text: 'Campo cadastrado com sucesso !',
              icon: 'success',
            })
            .then(willDelete => {
              if (willDelete) {
                location.reload();
              }
            });
        });
      }
    },
  },
};
</script>

<style scoped>
#Modal {
}
.table-padding {
  padding-bottom: 1em;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.btn-table-inner {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
}

.btn-table-inner-delete {
  color: #ffffff;
  border: solid 1px #dc3545;
  border-radius: 0.25rem;
  background: #dc3545;
}

.btn-table-inner-delete:hover {
  color: #dc3545;
  border-color: #dc3545;
  background-color: #ffffff;
  cursor: pointer;
}
.icon-table-inner {
  fill: currentColor;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}
.btn-push-option {
  display: flex;
  align-items: flex-end;
  justify-items: flex-end;
}
</style>
