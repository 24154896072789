<template>
  <div id="IndexHeader">
    <router-link :to="route">
      <b-button class="sidebar-button" @click="clearStore()">
        <span>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 22H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5"></path>
            <polyline points="17 16 21 12 17 8"></polyline>
            <line x1="21" x2="9" y1="12" y2="12"></line>
          </svg>
        </span>
        <span class="button-text">
          {{ text }}
        </span>
      </b-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'IndexHeader',
  components: {},
  props: {},
  data: function() {
    return {
      text: 'Sair',
      route: 'login',
    };
  },
  methods: {
    clearStore() {
      localStorage.removeItem('token');
    },
  },
};
</script>

<style scoped>
#IndexHeader {
  cursor: pointer;
  display: flex;
  align-items: start;
  justify-content: flex-end;
  margin-bottom: 1em;
  float: right;
  min-height: 40px;
  min-width: 100%;
  background-color: #c31d1f;
}

svg {
  width: 1.5em;
  height: 1.5em;
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.sidebar-button {
  color: #ffffff;
  border: none;
  background: none;
  text-align: left;
}

.sidebar-button:hover {
  text-decoration: none;
}

.sidebar-button:focus {
  box-shadow: none;
}

.sidebar-button:active {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.button-text {
  text-align: left;
  margin-left: 1em;
}
</style>
