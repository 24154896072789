import {Visit} from '@/models/Core/visit';
import {AxiosResponse} from 'axios';
import apiInstance from '../../config/index';
import {ListRequest, ServerResponse, ShowRequest} from '../../config/types';
import {DetailedVisit, StoreVisit, UpdateVisit} from './types';

/**
 * @return {Promise<ServerResponse<Visit>>}
 */
function getAllVisits(data: ListRequest = {}): Promise<ServerResponse<Visit>> {
  return apiInstance
    .get('/visitation-reason', {params: data})
    .then(({data}: AxiosResponse<ServerResponse<Visit>>) => data);
}

function getDetailedVisit(
  id: number,
  data: ShowRequest = {},
): Promise<DetailedVisit> {
  return apiInstance
    .get(`/visitation-reason/${id}`, {params: data})
    .then(({data}: AxiosResponse<DetailedVisit>) => data);
}

function newVisit(formData: StoreVisit): Promise<Visit> {
  return apiInstance
    .post('/visitation-reason', formData)
    .then(({data}: AxiosResponse<Visit>) => data);
}

function updateVisit(id: number, formData: UpdateVisit): Promise<Visit> {
  return apiInstance
    .put(`/visitation-reason/${id}`, formData)
    .then(({data}: AxiosResponse<Visit>) => data);
}

function deleteVisit(id: number): Promise<number> {
  return apiInstance
    .delete(`/visitation-reason/${id}`)
    .then(({data}: AxiosResponse<number>) => data);
}

const VisitService = {
  getAllVisits,
  getDetailedVisit,
  newVisit,
  updateVisit,
  deleteVisit,
};

export default VisitService;
