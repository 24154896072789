<template>
  <b-row>

    <b-col cols="12" sm="3" md="3" class="no-padding">
      <label>Cliente:</label>
      <v-text-field
      :class="classe"
      solo
      type="number"
      :disabled="disabled"
      v-model="dataCodigo"
      :label="labelCodigo"
      hide-details
      return-object
      >
        <template v-slot:prepend-inner>
          <img
            style="vertical-align:top;"
            width="20"
            height="49"
            alt=""
            src="../../assets/faixaCampoObrigatorio.png"
          />
        </template>
      </v-text-field>
    </b-col>

    <b-col cols="12" sm="9" md="9" class="no-padding">
      <label>⠀</label>
      <v-autocomplete
      :class="classe"
      solo
      persistent-hint
      :disabled="disabled"
      v-model="data"
      :items="entries"
      :search-input.sync="search"
      auto-select-first
      hide-no-data
      hide-details
      hide-selected
      item-text="pes_nome"
      item-value="pes_nome"
      :label="labelNome"
      :placeholder="placeholder"
      return-object
      >
        <template v-slot:prepend-inner>
          <img
            style="vertical-align:top;"
            width="20"
            height="49"
            alt=""
            src="../../assets/faixaCampoObrigatorio.png"
          />
        </template>
      </v-autocomplete>
    </b-col>

  </b-row>

</template>

<script>
import apiInstance from "../../services/api/config";
import swal from 'sweetalert2';

export default {
  name: 'ClientSearch',
  props: {
    placeholder: {},
    disabled: {
      default: false,
    },
    labelNome: {
      default: "Nome do cliente",
    },
    labelCodigo: {
      default: "Código",
    },
    limpaDados: {
      default: 0,
    },
    classe: {
      default: '',
    },
  },
  data() {
    return {
      data: '',
      dataCodigo: '',
      entries: [],
      search: null,
      noSearch: false,
    };
  },
  methods: {
    getServicos() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        if (this.search.length >= 3) {
        apiInstance
            .get('/personComplete?name=' + this.search)
            .then(res => {
              this.entries = res.data.data;
            });
        }
      }, 500)
    },
    getServicosCodigo() {
      if(this.dataCodigo != null && this.dataCodigo != '' && this.dataCodigo != undefined){
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          if(this.dataCodigo != undefined){
            apiInstance
              .get('/personComplete/' + this.dataCodigo)
              .then(res => {
                this.noSearch = true;
                if(res.data.data != undefined){
                  this.data = res.data.data;
                }else{
                  this.data = [];
                }
              })
              .catch((error) => {
                console.log(error);
                 swal
                .fire({
                  title: '',
                  text: 'Nenhum cliente encontrado com o código ' + this.dataCodigo,
                  icon: 'error',
                })
              })
          }
        }, 500)
      }
    },
  },

  computed: {},

  watch: {
    async search() {
      if(this.search != null && this.search != ''){
        if(this.search.length == 3){
          this.noSearch = false;
        }
        if(this.noSearch == false){
            await this.getServicos();
        }else{
          this.noSearch == false
        }
      }else{
        this.data = {}
        this.noSearch == false
      }
    },
    dataCodigo(){
      if(this.dataCodigo != null && this.dataCodigo != ''){
        this.noSearch = false;
        if(this.noSearch == false){
          this.getServicosCodigo();
        }
      }
    },
    data(){
      if(this.data != null && this.data != '' && this.data != undefined ){
        if(this.data.length != 0){
          this.dataCodigo = this.data.pes_codigo
        }
      }
      this.$emit('update:value', this.data);
    },
    limpaDados(){
      console.log('limpa')
      this.dataCodigo = null
      this.entries = []
      this.data = {
        pes_nome: "",
        pes_codigo: "",
      }
    }
  },
};
</script>