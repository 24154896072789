<template>
  <div id="Main">
    <Title title="Arquivos Gerados" />

    <div>
      <div class="box">
        <b-row>
          <label class="nameBox pl-2 pr-2">Pesquisa</label>
        </b-row>

        <b-row class="pb-4">

          <b-col cols="12" sm="2" md="2">
            <InterfaceSelect
              label='Ano'
              :entries="listaAno"
              :data="searchAno"
              @update:value="searchAno = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2">
            <InterfaceSelect
              label='Mês'
              :entries="listaMes"
              :data="searchMes"
              @update:value="searchMes = $event"/>
          </b-col>

          <b-col cols="12" sm="2" md="2" class="text-left pt-10">
            <v-btn style="width:100%" @click="limpaFiltro()">Limpar</v-btn>
          </b-col>

        </b-row>
      </div>

      <div class="box">
        <b-row>
          <label class="nameBox pl-2 pr-2">Arquivos</label>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items="listaArquivos"
              :items-per-page="15"
              :page.sync="page"
              @page-count="pageCount = $event"
              :sort-by="'nfsta_date_insert'"
              :sort-desc= true
              :search="search"
              item-key="identificador"
              hide-default-footer
              class="elevation-1 estilo-tabela"
              :loading="loadingTabela"
              loading-text="Carregando...">

              <template v-slot:item="{item}">
                <tr 
                :style="getColor(item)">
                  <td>{{item.nfsta_codigo}}</td>
                  <td>{{item.nfsta_usu_insert}}</td>
                  <td>{{formataData(item.nfsta_date_insert,'DD/MM/YYYY')}}</td>
                  <td>{{formataData(item.nfsta_date_insert,'HH:mm:ss')}}</td>
                  <td>{{item.nfsta_tipo_arquivo}}</td>
                  <td>
                    <DownloadButton
                      :text="item.nfsta_nome_arquivo"
                      @add-fields-button="download(item)"
                    />
                  </td>
                  <td>
                    <v-icon
                      v-show="item.nfsta_tipo_arquivo == 1 "
                      class="mr-2 icon_acoes"
                      @click="openInfo(item)"
                      title="Lista dos Arquivos">
                      mdi-magnify
                    </v-icon>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                  <h4 class="mb-1 mt-1 no-data">
                    {{noData}}
                  </h4>
                </template>
              
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
                class="pagination"
                v-model="page"
                :length="pageCount"
            ></v-pagination>
          </div>
          </b-col>

        </b-row>
      </div>
    </div>
  <InfoArquivo id="infoArquivo" :item="item" :limpadados="limpadados"/>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import Title from '@/components/Nfsc_Nfst/Title';
import Button from '@/components/Nfsc_Nfst/Button';
import DownloadButton from '@/components/Nfsc_Nfst/DownloadButton';
import InfoArquivo from '@/components/Nfsc_Nfst/InfoArquivo';
import moment from "moment";
import apiInstance from "../../services/api/config";
import TableFilter from '@/components/Nfsc_Nfst/TableFilter';
import DefaultDatePicker from '@/components/generics/DefaultDatePicker';
import InterfaceSelect from '@/components/generics/InterfaceSelect';
import InterfaceText from '@/components/generics/InterfaceText';
import { saveAs } from 'file-saver';

export default {
  name: 'Arquivo',
  components: {
    DefaultDatePicker,
    TableFilter,
    Button,
    Title,
    InfoArquivo,
    DownloadButton,
    InterfaceSelect,
    InterfaceText,
    saveAs,
  },
  data() {
    return {
      loadingTabela: true,
      page: 1,
      pageCount: 0,
      limpadados: 0,
      listaMes: ['01','02','03','04','05','06','07','08','09','10','11','12'],
      listaAno: [],
      searchMes: '',
      searchAno: '',
      search: '',
      dataHoje: '',
      item: {},
      listaArquivos:[],
      headers: [
        {text: 'Código', value: 'nfsta_codigo', sortable: false},
        {text: 'Usuário', value: 'nfsta_usu_insert', sortable: false},
        {text: 'Data', value: 'nfsta_date_insert', sortable: false},
        {text: 'Hora', value: 'hora', sortable: false},
        {text: 'Tipo', value: 'nfsta_tipo_arquivo', sortable: false},
        {text: 'Download', value: 'download', sortable: false},
        {text: 'Opções', value: 'actions', sortable: false}
      ],
      noData: 'Nenum arquivo gerado ',
    };
  },
  created () {
      this.initialize()
    },
  watch:{
    searchAno(){
      this.search = this.searchAno + (this.searchAno!=''?'-':'') + this.searchMes + (this.searchMes!=''?'-':'')
    },
    searchMes(){
      this.search = this.searchAno + (this.searchAno!=''?'-':'') + this.searchMes + (this.searchMes!=''?'-':'')
    }
  },
  methods: {
    initialize () {
      apiInstance
        .get('/fiscal/Nfsta')
        .then(res => {
          this.listaArquivos = res.data.data;
          this.loadingTabela = false;
        });
      var j = 0
      this.dataHoje = this.formataData(new Date,'YYYY')
      for(var i=Number(this.dataHoje) ; i>=2020 ; i--){
        this.listaAno[j] = i
        j++
      }
    },
    openInfo(item) {
      this.item = item;
      this.limpaDados += 1;
      this.$bvModal.show('infoArquivo');
    },
    getColor(item){
      var index = this.listaArquivos.indexOf(item)
      index -= 1
      if(((index/3).toFixed(0))%2 == 0){
          return 'background-color:rgb(255, 255, 255)'
      }else{
        return 'background-color:rgb(235, 235, 235)'
      }
    },
    download(item){

      window.open(process.env.VUE_APP_DOC_FISCAL_URL + item.nfsta_nome_arquivo);

      //window.open("http://localhost/advanced-line/public/DOCUMENTO_FISCAL/"+item.nfsta_nome_arquivo + ".txt"); 

      /*apiInstance
        .get('/fiscal/download/' + item.nfsta_codigo)
        .then(res => {
           window.open("http://localhost/advanced-line/public/DOCUMENTO_FISCAL/"+item.nfsta_nome_arquivo+".txt"); */
          /*var mydataToSend = encodeURIComponent(item.nfsta_arquivo);
          window.open("latinDownload.php?a=" + mydataToSend + "&filename=" + item.nfsta_nome_arquivo );*/

          /*var textFileAsBlob = new Blob([item.nfsta_arquivo], {type:'text/plain'});
          var downloadLink = document.createElement("a");
          downloadLink.download = item.nfsta_nome_arquivo;
          downloadLink.innerHTML = "Download File";
          if (window.webkitURL != null)
          {
              downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
          }
          else
          {
              downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
              downloadLink.onclick = destroyClickedElement;
              downloadLink.style.display = "none";
              document.body.appendChild(downloadLink);
          }
          downloadLink.click();*/


          /*var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=ISO-8859-1,' + encodeURIComponent(item.nfsta_arquivo));
          element.setAttribute('download', item.nfsta_nome_arquivo);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);/*

          /*var FileSaver = require('file-saver');
          var blob = new Blob([ new Uint8Array([0xEF, 0xBB, 0xBF]), item.nfsta_arquivo], {type:"text/plain;encoding=utf-8"});
          FileSaver.saveAs(blob, item.nfsta_nome_arquivo);*/

          //var base64 = btoa(unescape(encodeURIComponent(item.nfsta_arquivo)));
          //window.open("data:text/plain;charset=ISO-8859-1;base64,"+base64,"ISO-8859-1 Text");
        //});
    },
    limpaFiltro(){
      this.searchMes=""
      this.searchAno=""
      this.search = ""
    },
    formataData(data, formato){
      return moment(String(data)).format(formato)
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.formBordas{
  border: solid 1px  rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.formBordas2{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px;
  margin-bottom: 20px;
}
.aling-left{
  text-align: left;
}
.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}
.no-padding{
  padding: 0px 5px;
}
.no-padding-bottom{
  padding-bottom: 0px;
}
.no-data{
  font-size: 15px;
}

.box{
  border: solid 1px  rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px 20px 30px 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.nameBox{
  background-color: #ffffff;
}

</style>

<style>
  .modal-footer{
    justify-content: center;
  }
  .tam-imposto-tabela{
    width: 130px;
    text-align: right;
  }
  .no-spin-buttons input[type='number'] {
    -moz-appearance:textfield;
  }
  .no-spin-buttons input::-webkit-outer-spin-button,
  .no-spin-buttons input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>
