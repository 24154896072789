const ordemProducaoStore = {
  state: {
    example: [],
  },
  mutations: {
    MUT_EXAMPLE(state: any, payload: any) {
      state.example = payload;
    },
  },
  actions: {
    async a_ordemProducaoStore_example(context: any, payload: any) {
      context.commit('MUT_EXAMPLE', payload);
    },
  },
  getters: {},
};

export default ordemProducaoStore;
