<template>
  <div id="UserName">
    <b-input-group>
      <template v-slot:prepend>
        <b-input-group-text class="login-prepend">
          <b-icon-person-fill class="login-icon" />
        </b-input-group-text>
      </template>
      <b-form-input
        @focusin="focus = true"
        @focusout="focus = false"
        class="name-input"
        placeholder="Usuário"
        type="text"
        v-model="inputValue"
        v-on:input="$emit('user-name-change', inputValue)"
      />
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'UserName',
  data() {
    return {
      inputValue: '',
    };
  },
};
</script>

<style scoped>
#UserName {
  margin-bottom: 1em;
}
.name-input {
  background-color: #ffffff;
  border: solid 1px #ced4da;
  border-left: solid 1px #c31d1f;
}
.name-input:focus {
  background-color: #ffffff;
  border-color: #c31d1f;
  box-shadow: none;
}
</style>
