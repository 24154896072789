<template>
  <div>
    <ProductSearch label="Componente do Produto" @update:value="componente = $event"/>
    <div class="componente">
      <v-text-field class="componente__input--medio" label="Quantidade" solo type="number"/>
      <div class="componente__espaco"/>
      <v-text-field label="Preço" solo type="number"/>
      <div class="componente__espaco"/>
      <v-text-field label="Quantidade" solo type="number"/>
    </div>
    <div class="submit">
      <DefaultCheckbox label="Controla Estoque" :initial-value="false" @update:value="controlaEstoque = $event"/>

      <v-btn @click="addItem"> Adicionar</v-btn>
      <div style="width: 100px"/>
    </div>

  </div>
</template>

<script>
import ProductSearch from "@/components/search-components/ProductSearch";
import DefaultCheckbox from "@/components/inputs/DefaultCheckbox";

export default {
  name: 'ProductListForm',
  components: {DefaultCheckbox, ProductSearch},
  props: {
    edit: {},
    delete: {},
  },
  data() {
    return {
      componente: null,
      controlaEstoque: false
    }
  },
  methods: {
    addItem() {
      console.log('save')
    }
  },
  watch: {
    componente(value) {
      console.log(value)
    },
    edit() {

    },
    delete() {

    },
  }
}
</script>

<style scoped>
.componente {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.componente__espaco {
  width: 30px;
}

.componente__input--medio {
  width: 150px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  align-items: center;
}
</style>