import swal from "sweetalert2";
<template>
  <div id="Modal">
    <b-modal
      :id="id"
      @hidden="resetModal"
      @ok="handleOk"
      @show="resetModal"
      @hide="$emit('reset-modal')"
      cancelTitle="Cancelar"
      cancelVariant="outline-secondary"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      okTitle="Salvar"
      okVariant="dark"
      ref="modal"
      size="xl"
      title="Formulários"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
        <b-form-group :state="stateName" label="Nome do formulário:">
          <b-form-input
            :state="stateName"
            placeholder="Label..."
            required
            v-model="name"
          />
        </b-form-group>

        <b-form-group
          :state="stateDescription"
          label="Descrição do formulário:"
        >
          <b-form-input
            :state="stateDescription"
            placeholder="Descrição..."
            required
            v-model="description"
          />
        </b-form-group>

        <!--        <b-form-group :state="stateStatus" label="Status do formulário:">-->
        <!--          <b-form-select-->
        <!--            :state="stateStatus"-->
        <!--            :options="statusOptions"-->
        <!--            required-->
        <!--            v-model="status"-->
        <!--          >-->
        <!--            <template v-slot:first>-->
        <!--              <b-form-select-option value="" disabled-->
        <!--                >Selecione uma opções...</b-form-select-option-->
        <!--              >-->
        <!--            </template>-->
        <!--          </b-form-select>-->
        <!--        </b-form-group>-->

        <SortSelection
          :options="camposOptions"
          :edit="camposEdit"
          v-on:selected-change="selectedChange($event)"
        />
      </form>
      <div class="message-alert">
        {{ messageAlert }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import SortSelection from '@/components/formularios/SortSelection';
import {FormService} from '@/services';
import swal from 'sweetalert2';

export default {
  name: 'Modal',
  components: {SortSelection},
  props: {
    id: String,
    row: Object,
    action: String,
  },
  data() {
    return {
      name: '',
      description: '',
      status: 1,
      campos: [],
      messageAlert: '',
      camposEdit: [],

      statusOptions: [
        {value: 1, text: 'Ativo'},
        {value: 0, text: 'Inativo'},
      ],
      camposOptions: [],
    };
  },
  computed: {
    stateName() {
      return this.name.length > 0;
    },
    stateDescription() {
      return this.description.length > 0;
    },
    stateStatus() {
      return this.status === 0 || this.status === 1;
    },
  },
  methods: {
    selectedChange(event) {
      this.campos = [];
      event.forEach(element => {
        this.campos.push(element.codigo);
      });
    },
    resetModal() {
      this.name = '';
      this.description = '';
      this.status = 1;
      this.campos = [];
      this.camposEdit = [];
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.campos.length === 0) {
        swal.fire(
          '',
          'Por favor, adicione ao menos um campo ao seu formulário',
          'warning',
        );
      } else if (this.name !== '' && this.description !== '') {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      if (!this.stateName || !this.stateDescription || !this.stateStatus) {
        this.messageAlert =
          'Verifique se todos os campos foram preenchidos corretamente!';
        return;
      }
      if (this.action === 'new') {
        FormService.newForm({
          form_nome: this.name,
          form_descricao: this.description,
          form_status: this.status,
          campos: this.campos,
        }).then(res => {
          this.$bvModal.hide(this.id);
          location.reload();
        });
      }
      if (this.action === 'edit') {
        FormService.updateForm(this.row.codigo, {
          form_nome: this.name,
          form_descricao: this.description,
          form_status: this.status,
          campos: this.campos,
        }).then(res => {
          this.$bvModal.hide(this.id);
          location.reload();
        });
      }
    },
  },
  mounted() {
    if (this.action === 'new') {
      this.status = 1;
    }
    FormService.getAllFields({orderBy: 'cam_label', perPage: false}).then(
      res => {
        //{with: ['fields']}
        res.data.forEach(field => {
          this.camposOptions.push({
            text: field.cam_label,
            value: {
              label: field.cam_label,
              codigo: field.cam_codigo,
            },
          });
        });
      },
    );
  },
  watch: {
    row() {
      if (this.row.name) {
        this.name = this.row.name;
      }
      if (this.row.description) {
        this.description = this.row.description;
      }
      if (this.row.status) {
        this.status = this.row.status;
      }
      if (this.row.collapse) {
        this.row.collapse.forEach(element => {
          this.camposEdit.push({
            label: element.cam_label,
            codigo: element.cam_codigo,
          });
        });
      }
    },
  },
};
</script>

<style scoped>
#Modal {
}
.message-alert {
  margin-top: 1em;
  color: #dc3545;
  font-size: 14px;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}
</style>
