<template>
  <div id="ButtonReset">
    <button class="button-reset" type="reset" v-on:click="$emit('login-reset')">
      Limpar
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonReset',
};
</script>

<style scoped>
#ButtonReset {
  display: inline-block;
}
.button-reset {
  background-color: #ffffff;
  border: solid 1px #777777;
  border-radius: 0.25rem;
  color: #777777;
  padding: 0.25em;
  width: 8em;
}
.button-reset:hover {
  background-color: #777777;
  color: #ffffff;
}
</style>
