<template>
  <div id="Index">
    <Sidebar class="menu-sidebar" :width-img="widthImg"/>
    <div class="menu-body">
      <Header/>
      <div class="body-margin">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/index/Sidebar';
import Header from '@/components/index/Header';

export default {
  name: 'Index',
  components: {
    Sidebar,
    Header,
  },
  data: function () {
    return {
      widthImg: '10em',
      fixedMenu: false,
    };
  },
};
</script>

<style scoped>
#Index {
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.body-margin {
  margin-left: 5%;
  margin-right: 5%;
}

.menu-sidebar {
  height: 100%;
}

.menu-body {
  width: 100%;
}
</style>
