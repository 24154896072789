import {Field, Form} from '@/models/Core/form';
import {AxiosResponse} from 'axios';
import apiInstance from '../../config/index';
import {ListRequest, ServerResponse, ShowRequest} from '../../config/types';
import {
  DetailedForm,
  StoreField,
  StoreForm,
  UpdateField,
  UpdateForm,
} from './types';

/**
 * Get all fields
 * @return {Promise<ServerResponse<Field>>}
 */
function getAllFields(data: ListRequest = {}): Promise<ServerResponse<Field>> {
  return apiInstance
    .get('field', {params: data})
    .then(({data}: AxiosResponse<ServerResponse<Field>>) => data);
}

/**
 * Get all forms
 * @return {Promise<ServerResponse<Form>>}
 */
function getAllForms(data: ListRequest = {}): Promise<ServerResponse<Form>> {
  return apiInstance
    .get('/form', {params: data})
    .then(({data}: AxiosResponse<ServerResponse<Form>>) => data);
}

/**
 * Get all forms
 * @return {Promise<ServerResponse<Form>>}
 */
function getAllGroupings(data: ListRequest = {}): Promise<ServerResponse<any>> {
  return apiInstance
    .get('/form-grouping', {params: data})
    .then(({data}: AxiosResponse<ServerResponse<any>>) => data);
}

/**
 * Get form with details
 * @param {number} id
 * @param {ShowRequest} data
 * @return {Promise<DetailedForm>}
 */
function getDetailedForm(
  id: number,
  data: ShowRequest = {},
): Promise<DetailedForm> {
  return apiInstance
    .get(`/form/${id}`, {params: data})
    .then(({data}: AxiosResponse<DetailedForm>) => data);
}

/**
 * Get form with details
 * @param {number} id
 * @param {ShowRequest} data
 * @return {Promise<DetailedField>}
 */
export function getDetailedField(
  id: number,
  data: ShowRequest = {},
): Promise<any> {
  return apiInstance
    .get(`/field/${id}`, {params: data})
    .then(({data}: AxiosResponse<any>) => data);
}

/**
 * Store form
 * @param {StoreForm} formData
 * @return {Promise<Form>}
 */
function newFormGrouping(formData: any): Promise<any> {
  return apiInstance
    .post('/form-grouping', formData)
    .then(({data}: AxiosResponse<any>) => data);
}

/**
 * Store form
 * @param {StoreForm} formData
 * @return {Promise<Form>}
 */
function newForm(formData: StoreForm): Promise<Form> {
  return apiInstance
    .post('/form', formData)
    .then(({data}: AxiosResponse<Form>) => data);
}

/**
 * Update form
 * @param id
 * @param {UpdateForm} formData
 * @return {Promise<Form>}
 */
function updateForm(id: number, formData: UpdateForm): Promise<Form> {
  return apiInstance
    .put(`/form/${id}`, formData)
    .then(({data}: AxiosResponse<Form>) => data);
}

/**
 * Update form
 * @param id
 * @param {UpdateForm} formData
 * @return {Promise<Form>}
 */
function updateFormGrouping(id: number, formData: any): Promise<any> {
  return apiInstance
    .put(`/form-grouping/${id}`, formData)
    .then(({data}: AxiosResponse<any>) => data);
}

/**
 * Delete form
 * @param {number} id
 * @return {Promise<number>}
 */
function deleteFormGrouping(id: number): Promise<number> {
  return apiInstance
    .delete(`/form-grouping/${id}`)
    .then(({data}: AxiosResponse<number>) => data);
}

/**
 * Delete form
 * @param {number} id
 * @return {Promise<number>}
 */
function deleteForm(id: number): Promise<number> {
  return apiInstance
    .delete(`/form/${id}`)
    .then(({data}: AxiosResponse<number>) => data);
}

/**
 * Store field
 * @param {StoreField} fieldData
 * @return {Promise<Field>}
 */
function newField(fieldData: StoreField): Promise<Field> {
  return apiInstance
    .post('/field', fieldData)
    .then(({data}: AxiosResponse<Field>) => data);
}

/**
 * Update field
 * @param id
 * @param {UpdateField} fieldData
 * @return {Promise<Field>}
 */
function updateField(id: number, fieldData: UpdateField): Promise<Field> {
  return apiInstance
    .put(`/field/${id}`, fieldData)
    .then(({data}: AxiosResponse<Field>) => data);
}

/**
 * Delete field
 * @param {number} id
 * @return {Promise<number>}
 */
function deleteField(id: number): Promise<number> {
  return apiInstance
    .delete(`/field/${id}`)
    .then(({data}: AxiosResponse<number>) => data);
}

const FormService = {
  deleteField,
  deleteForm,
  getDetailedForm,
  getAllFields,
  getAllForms,
  newField,
  newForm,
  updateField,
  updateForm,
  getDetailedField,
  getAllGroupings,
  deleteFormGrouping,
  newFormGrouping,
  updateFormGrouping,
};

export default FormService;
