import {UserState} from '@/store/user/types';

const user = {
  state: {
    isAuthenticated: false,
  } as UserState,
  mutations: {
    changeAuthentication(state: UserState, value: boolean) {
      state.isAuthenticated = value;
    },
  },
  actions: {},
  modules: {},
};

export default user;
