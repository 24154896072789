<template>
  <div id="Button">
    <router-link :to="route">
      <b-button class="sidebar-button">
        <span class="button-text">
          {{ text }}
        </span>
      </b-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    route: String,
  },
  data: function() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
#Button:hover {
  cursor: pointer;
}

.sidebar-button {
  color: #999;
  border: solid 1px #ffffff;
  border-radius: 0;
  background: none;
  width: 100%;
  text-align: left;
  padding-left: 1em;
}
.sidebar-button:hover {
  color: #c31d1f;
  /*border: solid 1px #28a745;*/
  /*text-decoration: none;*/
}
.sidebar-button:focus {
  box-shadow: none !important;
}
.sidebar-button:active {
  color: #ffffff;
  background-color: #c31d1f !important;
  box-shadow: none !important;
  border: solid 1px #ffffff !important;
}
.button-text {
  text-align: left;
  margin-left: 1em;
}
</style>
