<template>
  <div>
    <b-row class="aling-left">
      <b-col cols="12" sm="6" md="6" class="no-padding">
        <label>Cód. produto:</label>
        <v-autocomplete
          solo
          auto-select-first
          type="number"
          persistent-hint
          :disabled="disabled"
          v-model="data"
          :items="entries"
          :search-input.sync="searchCode"
          hide-no-data
          hide-selected
          item-text="pve_codigo"
          item-value="pve_codigo"
          :label="labelCode"
          :placeholder="placeholder"
          return-object
          @keyup.native.enter="enterItemsCode"
          @keyup.native.tab="enterItemsCode"
        >
          <template v-if="CampoObrigatorio" v-slot:prepend-inner>
            <img
              style="vertical-align:top;"
              width="20"
              height="49"
              alt=""
              src="../../assets/faixaCampoObrigatorio.png"
            />
          </template>
        </v-autocomplete>
      </b-col>
      <b-col cols="12" sm="6" md="6" class="no-padding">
        <label>Cód. referência:</label>
        <v-autocomplete
          class="obrigatorio"
          solo
          auto-select-first
          persistent-hint
          :disabled="disabled"
          v-model="data"
          :items="entries"
          :search-input.sync="searchCodeRef"
          hide-no-data
          hide-selected
          item-text="pve_codigo_referencia"
          item-value="pve_codigo"
          :label="labelCodeRef"
          :placeholder="placeholder"
          return-object
          @keyup.native.enter="enterItemsCodeRef"
        >
          <template v-if="CampoObrigatorio" v-slot:prepend-inner>
            <div style="width:20px"></div>
          </template>
        </v-autocomplete>
      </b-col>
    </b-row>
    <b-row class="aling-left">
      <b-col cols="12" sm="3" md="3" class="no-padding">
        <label>Cód. barras:</label>
        <v-autocomplete
          solo
          auto-select-first
          persistent-hint
          :disabled="disabled"
          v-model="data"
          :items="entries"
          :search-input.sync="searchCodeBarras"
          hide-no-data
          hide-selected
          item-text="pve_codigo_barras"
          item-value="pve_codigo"
          :label="labelCodeBarras"
          :placeholder="placeholder"
          return-object
          @keyup.native.enter="enterItemsCodeBarras"
        >
          <template v-if="CampoObrigatorio" v-slot:prepend-inner>
            <div style="width:20px"></div>
          </template>
        </v-autocomplete>
      </b-col>
      <b-col
        cols="12"
        :sm="isQtdeVisible ? '6' : '9'"
        :md="isQtdeVisible ? '6' : '9'"
        class="no-padding"
      >
        <label style="margin-bottom: 24px"></label>
        <v-autocomplete
          solo
          auto-select-first
          :disabled="disabled"
          v-model="data"
          :items="entries"
          :search-input.sync="searchName"
          hide-no-data
          hide-selected
          item-text="product_complete_name"
          item-value="pve_codigo"
          :label="labelName"
          :placeholder="placeholder"
          return-object
          @keyup.native.enter="enterItemsName"
        >
          <template v-if="CampoObrigatorio" v-slot:prepend-inner>
            <img
              style="vertical-align:top;"
              width="20"
              height="49"
              alt=""
              src="../../assets/faixaCampoObrigatorio.png"
            />
          </template>
        </v-autocomplete>
      </b-col>
      <b-col v-show="isQtdeVisible" cols="12" sm="3" md="3">
        <slot name="qtde"></slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiInstance from '../../services/api/config';
import swal from 'sweetalert2';

export default {
  name: 'ProductSearchComp',
  props: {
    labelCode: {
      default: '',
    },
    labelCodeRef: {
      default: '',
    },
    labelCodeBarras: {
      default: '',
    },
    labelName: {
      default: '',
    },
    placeholder: {},
    disabled: {
      default: false,
    },
    limpaDados: {
      default: 0,
    },
    CampoObrigatorio: {
      default: false,
    },
    isQtdeVisible: {
      default: false,
    },
  },
  data() {
    return {
      data: '',
      emitData: [],
      firstEntries: [],
      entries: [],
      searchCode: null,
      searchCodeString: null,
      searchCodeRef: null,
      searchCodeBarras: null,
      searchName: null,
      noSearch: false,
    };
  },
  methods: {
    getItemsCode() {
      apiInstance
        .get('/composite-product/get?pve_codigo=' + this.searchCode)
        .then(res => {
          this.resultado(res);
        });
    },
    enterItemsCode() {
      if (this.searchCode.length >= 1) {
        this.buscando();
        this.getItemsCode();
      }
    },
    getItemsCodeRef() {
      apiInstance
        .get(
          '/composite-product/get?pve_codigo_referencia=' + this.searchCodeRef,
        )
        .then(res => {
          this.resultado(res);
        });
    },
    enterItemsCodeRef() {
      if (this.searchCodeRef.length >= 1) {
        this.buscando();
        this.getItemsCodeRef();
      }
    },
    getItemsCodeBarras() {
      apiInstance
        .get(
          '/composite-product/get?pve_codigo_barras=' + this.searchCodeBarras,
        )
        .then(res => {
          this.resultado(res);
        });
    },
    enterItemsCodeBarras() {
      if (this.searchCodeBarras.length >= 1) {
        this.buscando();
        this.getItemsCodeBarras();
      }
    },
    getItemsName() {
      apiInstance
        .get('/composite-product/get?product_complete_name=' + this.searchName)
        .then(res => {
          this.resultado(res);
        });
    },
    enterItemsName() {
      if (this.searchName.length >= 1 && this.searchName.length < 20) {
        this.getItemsName();
      }
    },
    buscando() {
      swal.fire({
        title: '',
        html: '<span class="mdi mdi-spin mdi-loading"></span>' + ' Buscando...',
        showConfirmButton: false,
      });
    },
    resultado(res) {
      if (res.data.length == 0) {
        swal.fire({
          title: '',
          html: ' Nenhum resuldado encontrado!',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.firstEntries = res.data;
        for (let i = 0; i < res.data.length; i++) {
          this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
        }
        this.entries = this.firstEntries;
        swal.fire({
          title: '',
          showConfirmButton: false,
          timer: 1,
        });
        if (res.data.length == 1) {
          this.data = this.entries[0];
        }
      }
    },
    getFloatValue(value) {
      return parseFloat((value || '').replace(/[^\d\\.,]/, ''));
    },
  },

  watch: {
    async searchName() {
      if (this.searchName != null && this.searchName.length < 20) {
        if (this.searchName.length == 3) {
          await this.getItemsName();
        }
      }
    },
    data() {
      if (this.data != null) {
        this.data.pve_controla_estoque = 1;
        this.$emit('update:value', this.data);
      } else {
        const nullData = {
          pve_codigo: '',
        };
        this.$emit('update:value', nullData);
        this.entries = [];
      }
    },
    limpaDados() {
      this.searchCode = null;
      this.searchCodeRef = null;
      this.searchCodeBarras = null;
      this.searchName = null;
      this.entries = [];
      this.data = null;
    },
  },
};
</script>

<style scoped>
.aling-left {
  text-align: left;
}
.v-list-item__title {
  text-align: left;
}
.obrigatorio {
  padding-left: 20px;
}
</style>
