<template>
  <div id="Button" v-on:click="$emit('add-fields-button')">
    <b-input-group>
      <b-input-group-prepend class="input-group-svg">
        <b-icon-plus />
      </b-input-group-prepend>
      <span class="input-group-text">
        {{ text }}
      </span>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
  },
};
</script>

<style scoped>
#Button {
  cursor: pointer;
  margin: 3em 0;
}
svg {
  width: 1.5em;
  height: 1.5em;
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.input-group-svg {
  color: #ffffff;
  background-color: #c31d1f;
  border: solid 1px #c31d1f;
  border-radius: 0.25em 0 0 0.25em;
  padding: 6px 12px;
  width: 50px;
  height: 38px;
}
.input-group-text {
  color: #c31d1f;
  background-color: #ffffff;
  border: solid 1px #c31d1f;
  border-radius: 0 0.25em 0.25em 0;
  width: calc(100% - 50px);
  display: inline-block;
  font-size: 16px;
  padding: 6px 12px;
}
.input-group-text:hover {
  color: #ffffff;
  background-color: #c31d1f;
  border-left-color: #c31d1f;
}
</style>
