<template>
  <div id="ButtonSubmit">
    <button
      class="button-submit"
      type="reset"
      v-on:click="$emit('login-submit')"
    >
      Entrar
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonSubmit',
};
</script>

<style scoped>
#ButtonSubmit {
  display: inline-block;
}
.button-submit {
  background-color: #c31d1f;
  border: solid 1px #c31d1f;
  border-radius: 0.25rem;
  color: #ffffff;
  padding: 0.25em;
  width: 8em;
}
.button-submit:hover {
  background-color: #ffffff;
  color: #c31d1f;
}
</style>
