<template>
  <div id="TableFilter">
    <b-input-group>
      <template v-slot:prepend>
        <b-input-group-text class="login-prepend">
          <b-icon-filter class="filter-icon" />
        </b-input-group-text>
      </template>
      <b-form-input
        class="filter-input"
        id="filterInput"
        placeholder="Digite o nome do produto..."
        type="search"
        v-model="filterInput"
        @keyup.native.enter="
          $emit('filter-input-change', {
            cam_label: filterInput !== '' ? filterInput : null,
          })
        "
      />
      <b-button
        class="btn-search"
        @click="
          $emit('filter-input-change', {
            cam_label: filterInput !== '' ? filterInput : null,
          })
        "
      >
        Pesquisar
      </b-button>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'TableFilter',
  data: function() {
    return {
      filterInput: '',
    };
  },
};
</script>

<style scoped>
#TableFilter {
  margin-bottom: 2em;
}
.filter-input {
  background-color: #ffffff;
  border: solid 1px #ced4da;
  border-left: solid 1px #c31d1f;
}
.filter-input:focus {
  background-color: #ffffff;
  border-color: #c31d1f;
  box-shadow: none;
}
.filter-icon {
  stroke: currentColor;
  width: 1.5em;
  height: 1.5em;
}
.btn-search {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: #c31d1f;
  border-color: #c31d1f;
}
</style>
