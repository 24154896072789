import {AxiosResponse} from 'axios';
import apiInstance from '../../config';
import {LoginData, LoginResponse} from './types';

function login(loginData: LoginData): Promise<boolean> {
  return apiInstance
    .post('/login', loginData)
    .then(
      ({data: {access_token: accessToken}}: AxiosResponse<LoginResponse>) => {
        localStorage.setItem('token', accessToken);
        return true;
      },
    )
    .catch(() => false);
}

const AuthService = {
  login,
};

export default AuthService;
