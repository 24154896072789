import swal from "sweetalert2";
<template>
  <div id="Modal">
    <b-modal
      :id="id"
      @hidden="resetModal"
      @ok="handleOk"
      @show="resetModal"
      @hide="$emit('reset-modal')"
      cancelTitle="Cancelar"
      cancelVariant="outline-secondary"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      okTitle="Salvar"
      okVariant="dark"
      ref="modal"
      size="xl"
      title="Grupo de Formulários"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
        <b-form-group :state="stateName" label="Nome do grupo:">
          <b-form-input :state="stateName" required v-model="name" />
        </b-form-group>

        <SortSelection
          :options="camposOptions"
          :edit="camposEdit"
          v-on:selected-change="selectedChange($event)"
        />
      </form>
      <div class="message-alert">
        {{ messageAlert }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import SortSelection from '@/components/agrupamentoFormularios/SortSelection';
import {FormService} from '@/services';
import swal from 'sweetalert2';

export default {
  name: 'Modal',
  components: {SortSelection},
  props: {
    id: String,
    row: Object,
    action: String,
  },
  data() {
    return {
      name: '',
      description: '',
      status: 1,
      campos: [],
      messageAlert: '',
      camposEdit: [],

      statusOptions: [
        {value: 1, text: 'Ativo'},
        {value: 0, text: 'Inativo'},
      ],
      camposOptions: [],
    };
  },
  computed: {
    stateName() {
      return this.name.length > 0;
    },
    stateDescription() {
      return this.description.length > 0;
    },
    stateStatus() {
      return this.status === 0 || this.status === 1;
    },
  },
  methods: {
    selectedChange(event) {
      this.campos = [];
      event.forEach(element => {
        this.campos.push(element.codigo);
      });
    },
    resetModal() {
      this.name = '';
      this.description = '';
      this.status = 1;
      this.campos = [];
      this.camposEdit = [];
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.campos.length === 0) {
        swal.fire(
          '',
          'Por favor, adicione ao menos um formulário ao seu grupo',
          'warning',
        );
      } else if (this.name !== '') {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      if (!this.stateName) {
        this.messageAlert =
          'Verifique se todos os campos foram preenchidos corretamente!';
        return;
      }
      if (this.action === 'new') {
        FormService.newFormGrouping({
          foag_descricao: this.name,
          forms: this.campos,
        }).then(res => {
          this.$bvModal.hide(this.id);
          location.reload();
        });
      }
      if (this.action === 'edit') {
        FormService.updateFormGrouping(this.row.foag_codigo, {
          foag_descricao: this.name,
          forms: this.campos,
        }).then(res => {
          this.$bvModal.hide(this.id);
          location.reload();
        });
      }
    },
  },
  mounted() {
    if (this.action === 'new') {
      this.status = 1;
    }
    FormService.getAllForms({
      form_status: 1,
      orderBy: 'form_nome',
      perPage: false,
    }).then(res => {
      //{with: ['fields']}
      res.data.forEach(field => {
        this.camposOptions.push({
          text: field.form_nome,
          value: {
            label: field.form_nome,
            codigo: field.form_codigo,
          },
        });
      });
    });
  },
  watch: {
    row() {
      if (this.row.foag_descricao) {
        this.name = this.row.foag_descricao;
      }

      if (this.row.collapse) {
        this.row.collapse.forEach(element => {
          this.camposEdit.push({
            label: element.form_nome,
            codigo: element.form_codigo,
          });
        });
      }
    },
  },
};
</script>

<style scoped>
#Modal {
}
.message-alert {
  margin-top: 1em;
  color: #dc3545;
  font-size: 14px;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}
</style>
