<template>
  <div>
    <v-checkbox
        v-model="checkbox"
        :label="label"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'DefaultCheckbox',
  props: {
    label: {
      default: 'Checkbox'
    },
    initialValue: {}
  },
  mounted() {
    this.checkbox = this.initialValue;
  },
  data() {
    return {
      checkbox: false
    }
  },
  watch: {
    checkbox(value) {
      this.$emit('update:value', value)
    },
    initalValue(value) {
      this.checkbox = value;
    }
  }
}
</script>