<template>
  <div id="Main">
    <Title title="Cadastro de Campos" />
    <Button
      text="Adicionar um Novo Campo"
      v-on:add-fields-button="addFieldsButton()"
    />
    <TableFilter
      v-show="!this.loading"
      v-on:filter-input-change="search(1, $event)"
    />
    <Table
      v-show="!this.loading && fields.length > 0"
      :rows="fields"
      :columns="columns"
    />
    <div class="text-center d-flex justify-content-center">
      <b-spinner
        v-show="this.loading"
        variant="danger"
        key="danger"
      ></b-spinner>
    </div>
    <b-pagination
      v-show="!this.loading && fields.length > 0"
      align="center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="fieldTable"
      @input="search"
    ></b-pagination>
    <p v-show="!this.loading && fields.length === 0">
      Nenhum registro encontrado.
    </p>
    <Modal id="addModal" />
  </div>
</template>

<script>
import Title from '@/components/kanbanProducao/Title';
import Button from '@/components/kanbanProducao/Button';
import Table from '@/components/kanbanProducao/Table';
import Modal from '@/components/kanbanProducao/Modal';
import {FormService} from '../../services';
import TableFilter from '@/components/kanbanProducao/TableFilter';

export default {
  name: 'Main',
  components: {
    TableFilter,
    Button,
    Title,
    Table,
    Modal,
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      perPage: 3,
      totalRows: 0,
      fields: [],
      columns: [
        {key: 'label', label: 'Texto', sortable: true},
        {key: 'type', label: 'Tipo', sortable: true},
        {key: 'collapse', label: 'Opções', sortable: false},
        {key: 'action', label: 'Ações', sortable: false},
      ],
    };
  },
  methods: {
    addFieldsButton() {
      this.$bvModal.show('addModal');
    },
    search(page, filters = null) {
      let params = {
        page: page,
      };

      if (filters) {
        params = {...filters, ...params};
      }
      this.loading = true;
      FormService.getAllFields(params)
        .then(res => {
          this.fields = [];
          this.currentPage = res.meta.current_page;
          this.perPage = res.meta.per_page;
          this.totalRows = res.meta.total;
          res.data.forEach(field => {
            this.fields.push({
              codigo: field.cam_codigo,
              label: field.cam_label,
              type: field.cam_tipo,
              collapse: field.cam_opcoes,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.search(1);
  },
};
</script>

<style scoped>
#Main {
}
</style>
