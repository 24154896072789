<template>
  <div id="histModal">
    <b-modal
        :id="id"
        centered
        header-bg-variant="dark"
        header-text-variant="light"
        ok-only
        okVariant="danger"
        ref="modal"
        size="xl"
        :title="'Lista de notas presentes no arquivo: ' + item.nfsta_codigo"
    >
        <template>
          <div class="formBordas">
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="itensEncontrados"
              class="elevation-1"
              :loading="loading"
              loading-text="Carregando..."
            >
            <template v-slot:item.actions="{ item }">
              <v-btn @click="createPDF(item)">
              <v-icon 
                small
                class="mr-2">
                  mdi-file-document
              </v-icon>
                PDF
              </v-btn>
              <div v-show="false">
                <v-data-table
                  hide-default-footer
                  ref="myTable"
                  :headers="headersImpressao"
                  :items="item.services"
                >
                </v-data-table>
              </div>
            </template>

          </v-data-table>
          </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import apiInstance from "../../services/api/config";
import ProductListForm from "@/components/list-components/ProductListForm"
import GenericTable from "@/components/tables/GenericTable";
import moment from "moment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoSigatel from "../../assets/Logos/05257922000100.png";

export default {
  name: 'InfoArquivo',
  components: {
    GenericTable,
    ProductListForm,
    logoSigatel,
  },
  props: ['id', 'item'],
  data() {
    return {
      loading: true,
      itensEncontrados: [],
      filiais:[],
      user: '',
      headers: [
        {text: 'Código', value: 'nfst_codigo', sortable: false},
        {text: 'Filial', value: 'nfst_filial', sortable: false},
        {text: 'Modelo nota', value: 'nfst_modelo', sortable: false},
        {text: 'CPF/CNPJ', value: 'nfst_cpf_cnpj', sortable: false},
        {text: 'Cliente', value: 'nfst_razao_social', sortable: false},
        {text: 'Terminal telefônico', value: 'nfst_nro_terminal_ou_uc', sortable: false},
        {text: 'Valor (R$)', value: 'nfst_valor_total', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      headersImpressao: [
        {text: ' ', value: 'nfsti_nro_ordem', sortable: false},
        {text: 'Serviço', value: 'nfsti_descricao', sortable: false},
        {text: 'Qtde', value: 'nfsti_quantidade', sortable: false},
        {text: 'Valor unitário (R$)', value: 'nfsti_valor_unitario', sortable: false},
        {text: 'Valor total (R$)', value: 'nfsti_valor_total', sortable: false},
      ],
    };
  },
  watch:{
    item(){
      this.itensEncontrados = [];
      this.loading = true;
      apiInstance
          .get('/user/me/person')
          .then(res => {
            this.user = res.data.pes_nome;
          });
      apiInstance
      .get('/company/branch?fil_pessoa=true')
      .then(res => {
        this.filiais = res.data.data;
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
        apiInstance
        .get('/fiscal/Nfst')
        .then(res => {
          for(var i=0 ; i<res.data.data.length ; i++){
            if(res.data.data[i].nfst_fk_nfsta_codigo == this.item.nfsta_codigo){
              this.itensEncontrados.push(res.data.data[i]);
            }
            for(var k=0 ; k<this.itensEncontrados.length ; k++){
                if(this.filiais.length == 1){
                  this.itensEncontrados.nfst_filial = this.filiais[0].fil_descricao
                }else{
                  for(var j=0 ; j<this.filiais.length ; j++){
                    if(this.itensEncontrados[k].nfst_cnpj_emitente == this.filiais[j].pessoa.pes_identificador){
                      this.itensEncontrados[k].nfst_filial = this.filiais[j].fil_descricao
                    }
                  }
                }
              }
            }
          this.loading = false;
          });
        }, 500)
      });
    },
  },
  methods: {
    
    formataData(data){
      return moment(String(data)).format('DD/MM/YYYY')
    },
    formataHora(data){
      return moment(String(data)).format('HH:mm')
    },
    telefone(v){
      v=v.replace(/\D/g,"")                 //Remove tudo o que não é dígito
      v=v.replace(/^(\d\d)(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
      v=v.replace(/(\d{4})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
      return v
    },
    identificador(v){
      console.log(v.length)
      if(v.length == 14){
        v=v.replace(/\D/g,"")
        v=v.replace(/(\d{2})(\d)/,"$1.$2")
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
        v=v.replace(/(\d{3})(\d)/,"$1/$2")
        v=v.replace(/(\d{4})(\d)/,"$1-$2")
        return v
      }else{
        v=v.replace(/\D/g,"")
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        return v
      }
    },
    createPDF(item) {
            var source =  this.$refs["myTable"];
            
            var marginTopPDF = 40;
            var marginTabela = marginTopPDF +60;
            let rows = [];
            var totICMS = 0
            var totPIS = 0
            var totCOFINS = 0 
            var telefone = this.telefone(item.nfst_nro_terminal_ou_uc)
            var identificador = this.identificador(item.nfst_cpf_cnpj)
            var user = this.user

            for(var i=0;i<item.services.length;i++){
              totICMS += Number(item.services[i].nfsti_valor_icms)
              totPIS += Number(item.services[i].nfsti_valor_pis)
              totCOFINS += Number(item.services[i].nfsti_valor_cofins)
            }

            function formataValor(valor){
              return String(Number(valor).toFixed(2)).replace('.',',')
            }
            function formataData(data, formato){
              return moment(String(data)).format(formato)
            }

            source.items.forEach(element => {
                  var temp = [
                    element.nfsti_nro_ordem,
                    element.nfsti_descricao,
                    element.nfsti_quantidade,
                    element.nfsti_valor_unitario,
                    element.nfsti_valor_total,
                ];
                
                rows.push(temp);
            });
            var doc = new jsPDF({
              orientation: "portrait",
            });
            var totalPagesExp = '{total_pages_count_string}'
            doc.autoTable({
              head:[[
                {content:' ', styles:{fillColor:[131,140,145]}},
                {content:'Serviço', styles:{fillColor:[131,140,145]}},
                {content:'Qtde', styles:{fillColor:[131,140,145]}},
                {content:'Valor unitário (R$)', styles:{fillColor:[131,140,145]}},
                {content:'Valor total (R$)', styles:{fillColor:[131,140,145]}},
              ]], 
              body:rows,
              startY: marginTabela,
              didDrawPage: function (data) {
                
                if(doc.internal.getNumberOfPages() == 1){
                  // Header
                  doc.setFontSize(20)
                  doc.setTextColor(40)

                  if(item.nfst_cnpj_emitente == '05257922000100'){
                    doc.addImage(logoSigatel, 'PNG', 130, 15, 60, 20)
                  }


                  //var imgData = 'data:image/png;base64,' + img
                  //doc.addImage(imgData, 'PNG', 15, 40, 180, 160)
                  
                  //Info
                  doc.setFontSize(12)
                  marginTopPDF += 10;
                  doc.text(item.nfst_razao_social, data.settings.margin.left, marginTopPDF,{maxWidth:100});
                  marginTopPDF += 15;
                    doc.text('Endereço: ' + item.nfst_end_logradouro + ', ' + item.nfst_end_numero, data.settings.margin.left, marginTopPDF,{maxWidth:100});
                    marginTopPDF += 6;
                    if(item.nfst_end_complemento == null){
                      doc.text('Bairro: ' + item.nfst_end_bairro, data.settings.margin.left, marginTopPDF,{maxWidth:100});
                    }else{
                      doc.text('Bairro: ' + item.nfst_end_bairro + '  Complemento: ' + item.nfst_end_complemento, data.settings.margin.left, marginTopPDF,{maxWidth:100});
                    }
                    marginTopPDF += 6;
                    doc.text('CEP: ' + item.nfst_end_cep + ' - ' + item.nfst_end_cidade + ', ' + item.nfst_end_uf, data.settings.margin.left, marginTopPDF,{maxWidth:100});

                    marginTopPDF -= 27;
                    doc.text('Número do telefone: ' + telefone, 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Identificação consumidor: ' + identificador, 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Modelo da nota: ' + item.nfst_modelo, 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Série da nota: ' + item.nfst_serie, 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Número da nota fiscal de telecomunicação: ' + item.nfst_numero, 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Data da emissão: ' + formataData(item.nfst_data_emissao, 'DD/MM/YYYY'), 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Ano/Mês referência: ' + item.nfst_ano_mes_referencia, 105, marginTopPDF,{maxWidth:100});

                    marginTopPDF += 6;
                    doc.text('Valor total a pagar: R$' + formataValor(item.nfst_valor_total_fat_comercial), 105, marginTopPDF,{maxWidth:100});

                    doc.setFillColor(255,255,200);
                    doc.rect(15, marginTopPDF+20+(item.services.length*10), 180, 10, 'S');
                    doc.text('Total de tributos:    ICMS = R$' + formataValor(totICMS) + '    PIS = R$' + formataValor(totPIS) + '    COFINS = R$' + formataValor(totCOFINS), 20, marginTopPDF+26+(item.services.length*10));

                }

                // Footer
                var str = 'Página ' + doc.internal.getNumberOfPages()
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                  str = str + ' de ' + totalPagesExp
                }
                str = str + ' - Impresso em ' + moment().format('L') + ' ' + moment().format('LT') + ' por ' +  user
                doc.setFontSize(10)

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text(str, data.settings.margin.left, pageHeight - 10)
              },
            });
            if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp)
            }
            doc.save(item.nfst_data_emissao + '-' + item.nfst_fk_pes_codigo + '.pdf');
      },
      
  },
};
</script>

<style scoped>
input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.formBordas{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em 0 0 0.25em;
  padding: 0px;
  margin-bottom: 20px;
}

</style>
