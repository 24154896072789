import {Person} from '@/models/Core/person';
import apiInstance from '@/services/api/config';
import {AxiosResponse} from 'axios';

function getAuthenticatedPerson(): Promise<Person | null> {
  return apiInstance
    .get('user/me/person')
    .then(({data}: AxiosResponse<Person>) => data)
    .catch(() => null);
}

const UserService = {
  getAuthenticatedPerson,
};

export default UserService;
