<template>
  <div>
    <v-autocomplete
        solo
        persistent-hint
        :disabled="disabled"
        v-model="data"
        :items="entries"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-text="usu_nome"
        item-value="usu_codigo"
        :label="label"
        :placeholder="placeholder"
        return-object
    />
  </div>
</template>

<script>
import apiInstance from "../../services/api/config";

export default {
  name: 'ProductSearchUser',
  props: {
    label: {
      default: '',
    },
    placeholder: {},
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      data: '',
      entries: [],
      search: null,
      noSearch: false,
    };
  },
  methods: {
    getItems() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        if (this.search.length >= 3) {
        apiInstance
            .get('/user?usu_nome=' + this.search)
            .then(res => {
              this.entries = res.data.data;
            });
        }
      }, 500)
    },
  },

  computed: {},

  watch: {
    async search() {
      if(this.search != null){
        if (this.search.length >= 3) {
          if(this.search.length == 3){
            this.noSearch = false
          }
          if(this.noSearch == false){
            await this.getItems();
          }
        }
      }else{
        this.data = {
          usu_nome: ''
        }
      }
      
    },
    data() {
      if(this.data != null){
        this.noSearch = true;
        this.$emit('update:value', this.data);
      }
    },
    disabled(){
      this.$emit('item-text', '');
    }
  },
};
</script>
