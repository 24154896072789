<template>
  <div>
    <v-autocomplete
        solo
        persistent-hint
        :disabled="disabled"
        v-model="data"
        :items="entries"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-text="product_complete_name"
        item-value="pve_codigo"
        :label="label"
        :placeholder="placeholder"
        return-object
    />
  </div>
</template>

<script>
import apiInstance from "../../services/api/config";

export default {
  name: 'ProductSearch',
  props: {
    label: {
      default: '',
    },
    placeholder: {},
    disabled: {
      default: false,
    }
  },
  data() {
    return {
      data: '',
      entries: [],
      search: null,
    };
  },
  methods: {
    getItems() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        apiInstance
            .get('/composite-product/get?product_complete_name=' + this.search)
            .then(res => {
              this.entries = res.data;
            });
      }, 500)
    },
  },

  computed: {},

  watch: {
    async search() {
      if (this.search.length >= 2 && this.search.length < 20) {
        await this.getItems();
      }
    },
    data() {
      this.$emit('update:value', this.data);
    }
  },
};
</script>