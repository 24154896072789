<template>
  <div id="Main">
    <Title title="Nota Fiscal de Serviço de Telecomunicação" />
    <Button
      text="Cadastrar Nota Fiscal de Serviço de Telecomunicação"
      @add-fields-button="addFieldsButton()"
    />
    <Button
      text="Gerar Arquivo"
      @add-fields-button="addArquivo()"
      />
    <div class="box">
      <b-row>
        <label class="nameBox pl-2 pr-2">Pesquisa</label>
      </b-row>

      <b-row>
        <b-col cols="12" sm="3" md="3" class="no-padding">
          <InterfaceSelect
          CampoObrigatorio = true
          classe="obrigatorio"
          topLabel="Filial:"
          :entries="filiais"
          itemText="fil_descricao"
          itemValue="fil_codigo"
          :data="filialSelect"
          @update:value="filialSelect = $event"/>
        </b-col>
        <b-col cols="12" sm="3" md="3" class="no-padding">
          <InterfaceSelect
          CampoObrigatorio = true
          classe="obrigatorio"
          topLabel="Modelo de Nota:"
          :entries="modelosNota"
          itemText="descricao"
          itemValue="codigo"
          :data="modeloNotaSelect"
          @update:value="modeloNotaSelect = $event"/>
        </b-col>
        <b-col cols="12" sm="2" md="2" class="no-padding pt-10 pl-3">
            <v-btn style="width:100%;" @click="limpaFiltros()">Limpar Filtros</v-btn>
          </b-col>
      </b-row>
    </div>


    <div class="box">

      <b-row>
        <label class="nameBox pl-2 pr-2">Registros de notas não enviadas</label>
      </b-row>

      <b-row>
        <b-col cols="12" sm="12" md="12">
          <v-data-table
            :headers="headers"
            :items="showNF"
            :items-per-page="20"
            item-key="identificador"
            hide-default-footer
            class="elevation-1 estilo-tabela"
            :loading="loadingTabela"
            loading-text="Carregando...">

            <template v-slot:top>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">
                    Tem certeza que quer excluir essa nota?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </template>


            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="editItem(item)">
                  mdi-magnify
              </v-icon>
              <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="deleteItem(item)">
                  mdi-delete
              </v-icon>
            </template>

            <template v-slot:no-data>
                <h4 class="mb-1 mt-1 no-data">
                  {{noData}}
                </h4>
              </template>
            
        </v-data-table>
        </b-col>

      </b-row>
    </div>

    <Modal id="addModal" :edit="edit" :item="item" :limpadados="limpadados"/>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import Title from '@/components/Nfsc_Nfst/Title';
import Button from '@/components/Nfsc_Nfst/Button';
import Modal from '@/components/Nfsc_Nfst/Modal';
import moment from "moment";
import apiInstance from "../../services/api/config";
import TableFilter from '@/components/Nfsc_Nfst/TableFilter';
import DefaultDatePicker from '@/components/generics/DefaultDatePicker';
import InterfaceSelect from '@/components/generics/InterfaceSelect';

export default {
  name: 'Main',
  components: {
    DefaultDatePicker,
    TableFilter,
    Button,
    Title,
    Modal,
    InterfaceSelect,
  },
  data() {
    return {
      loadingTabela: true,
      dialogDelete: false,
      pageCount: 0,
      page: 1,
      modeloNotaSelect: '',
      filialSelect: '',
      filiais: [],
      itemCompModal: {},
      idArquivo: [],
      item: {},
      listaNF:[],
      showNF:[],
      modelosNota:[
        {codigo: '21',descricao: '21 - NFSC', valor:'NFSC'},
        {codigo: '22',descricao: '22 - NFST', valor:'NFST'},
      ],
      headers: [
        {text: 'Código', value: 'nfst_codigo', sortable: false},
        {text: 'Filial', value: 'nfst_filial', sortable: false},
        {text: 'Modelo nota', value: 'nfst_modelo', sortable: false},
        {text: 'CPF/CNPJ', value: 'nfst_cpf_cnpj', sortable: false},
        {text: 'Cliente', value: 'nfst_razao_social', sortable: false},
        {text: 'Terminal telefônico', value: 'nfst_nro_terminal_ou_uc', sortable: false},
        {text: 'Valor (R$)', value: 'nfst_valor_total', sortable: false},
        {text: 'Opções', value: 'actions', sortable: false}
      ],
      noData: 'Nenhuma nota fiscal a ser enviada',
      edit: '',
      limpadados: 0,
      editedIndex: -1,
    };
  },
  created () {
      this.initialize()
    },
  watch:{
    modeloNotaSelect(){
      if(this.modeloNotaSelect != ''){
        this.showNF = []
        console.log(this.filialSelect)
        if(this.filialSelect == ''){
          for(var i = 0 ; i < this.listaNF.length ; i++){
            if(this.listaNF[i].nfst_modelo == this.modeloNotaSelect.codigo){
              this.showNF.push(this.listaNF[i]);
            }
          }
        }else{
         for(var i = 0 ; i < this.listaNF.length ; i++){
            if(this.listaNF[i].nfst_modelo == this.modeloNotaSelect.codigo && this.listaNF[i].nfst_filial == this.filialSelect.fil_descricao){
              this.showNF.push(this.listaNF[i]);
            }
          }
        }
      }
    },
    filialSelect(){
      if(this.filialSelect != ''){
        this.showNF = []
        console.log(this.filialSelect)
        if(this.modeloNotaSelect == ''){
          for(var i = 0 ; i < this.listaNF.length ; i++){
            if(this.listaNF[i].nfst_filial == this.filialSelect.fil_descricao){
              this.showNF.push(this.listaNF[i]);
            }
          }
        }else{
         for(var i = 0 ; i < this.listaNF.length ; i++){
            if(this.listaNF[i].nfst_modelo == this.modeloNotaSelect.codigo && this.listaNF[i].nfst_filial == this.filialSelect.fil_descricao){
              this.showNF.push(this.listaNF[i]);
            }
          }
        }
      }
    },
  },
  methods: {
    initialize () {   
      apiInstance
        .get('/company/branch?fil_pessoa=true')
        .then(res => {
          this.filiais = res.data.data;
        if(this.filiais.length == 1){
          this.filialSelect = this.filiais[0]
        }
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          apiInstance
          .get('/fiscal/Nfst')
          .then(res => {
            for(var i = 0 ; i < res.data.data.length ; i++){
              if(res.data.data[i].nfst_fk_nfsta_codigo == null){
                if(this.filiais.length == 1){
                  res.data.data[i].nfst_filial = this.filiais[0].fil_descricao
                }else{
                  for(var j=0 ; j<this.filiais.length ; j++){
                    if(res.data.data[i].nfst_cnpj_emitente == this.filiais[j].pessoa.pes_identificador){
                      res.data.data[i].nfst_filial = this.filiais[j].fil_descricao
                    }
                  }
                }
                this.listaNF.push(res.data.data[i]);
              }
            }
            this.showNF = this.listaNF
            this.loadingTabela = false;
          });
        }, 500)
        });

      
    },
    idSelect(){
      for(var i = 0 ; i < this.showNF.length ; i++){
            if(this.showNF[i].nfst_fk_nfsta_codigo == null && this.showNF[i].nfst_modelo == this.modeloNotaSelect.codigo && this.showNF[i].nfst_filial == this.filialSelect.fil_descricao)
            this.idArquivo[i] = this.showNF[i].nfst_codigo
          }
    },
    limpaFiltros(){
      this.filialSelect = ''
      this.modeloNotaSelect = ''
      this.showNF = this.listaNF
    },
    addFieldsButton() {
      this.edit = 0;
      this.limpadados += 1;
      this.$bvModal.show('addModal');
    },
    addArquivo(){
      if(this.filialSelect == '' || this.modeloNotaSelect == ''){
        swal.fire(
          '',
          'Para gerar um arquivo selecione a filial e o modelo de nota!',
          'warning',
      );
      return
      }
      if(this.showNF.length >= 1){
        this.idSelect()
        const obj = 
        {
          "itens" : this.idArquivo,
        }
        swal.fire({
          title: '',
          html:
            '<span class="mdi mdi-spin mdi-loading"></span>' +
            ' Gerando Arquivo...',
          showConfirmButton: false,
        });
        
        apiInstance
          .post('/fiscal/geraDocumento', obj)
          .then((response) => {
            swal
                .fire({
                  title: '',
                  text: 'Arquivo Gerado com sucesso!',
                  icon: 'success',
                  timer: 2000,
                })
                .then(willDelete => {
                  if (willDelete) {
                    location.reload();
                  }
                });
          })
          .catch((error) => {
            console.log(error);
          })
      }else{
        swal.fire(
          '',
          'Nenhum registro de nota não enviado!',
          'warning',
      );
      return
      }
      this.idArquivo = []
    },
    formataData(data){
      return moment(String(data)).format('DD/MM/YYYY')
    },
    editItem(item){
      this.item = item;
      this.edit += 1;
      this.limpadados += 1;
      this.$bvModal.show('addModal');
    },
    deleteItem (item) {
      this.editedIndex = this.showNF.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      apiInstance
        .delete('/fiscal/Nfst/' + this.showNF[this.editedIndex].nfst_codigo)
        .then(res => {
          this.showNF.splice(this.editedIndex, 1)
          this.closeDelete()
        });
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultServico)
        this.editedIndex = -1
      })
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.formBordas{
  border: solid 1px  rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.formBordas2{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px;
  margin-bottom: 20px;
}
.aling-left{
  text-align: left;
}
.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}
.no-padding{
  padding: 0px 5px;
}
.no-padding-bottom{
  padding-bottom: 0px;
}
.no-data{
  font-size: 15px;
}

.box{
  border: solid 1px  rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px 20px 30px 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.nameBox{
  background-color: #ffffff;
}

.obrigatorio.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
padding: 0px 12px 0px 0px!important;
}

</style>

<style>
  .modal-footer{
    justify-content: center;
  }
  .tam-imposto-tabela{
    width: 130px;
    text-align: right;
  }
  .no-spin-buttons input[type='number'] {
    -moz-appearance:textfield;
  }
  .no-spin-buttons input::-webkit-outer-spin-button,
  .no-spin-buttons input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

</style>
