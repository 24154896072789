<template>
  <div id="SortSelect">
    <label>Pesquise os formulários que compõem o grupo:</label>

    <div
      v-for="(item, index) in selected"
      v-bind:key="index"
      class="selected-box"
    >
      <b-row cols="4">
        <b-col md="1" class="selected-text-border">
          <b class="selected-index">{{ index + 1 }}</b>
        </b-col>
        <b-col md="1">
          <b-row cols="1">
            <b-col class="selected-icon" v-on:click="selectedUp(index)">
              <b-icon-chevron-up class="menu-collapse-icon" />
            </b-col>
            <b-col class="selected-icon" v-on:click="selectedDown(index)">
              <b-icon-chevron-down class="menu-collapse-icon" />
            </b-col>
          </b-row>
        </b-col>
        <b-col md="9" class="selected-text-border">
          <div class="selected-text">
            {{ item.label }}
          </div>
        </b-col>
        <b-col
          md="1"
          class="selected-text-border"
          v-on:click="selectedRemove(index)"
        >
          <b-icon-x class="selected-icon" />
        </b-col>
      </b-row>
    </div>

    <b-input-group class="my-3">
      <b-form-input
        placeholder="Digite o nome do formulário"
        v-model="field"
      ></b-form-input>
      <b-input-group-append>
        <b-button style="background: #C31D1F; border-color: #C31D1F"
          >Pesquisar</b-button
        >
      </b-input-group-append>
    </b-input-group>

    <div class="fields-box">
      <div
        @click="insertField(field.value)"
        class="line-field"
        v-for="field in filterFields"
      >
        {{ field.text }}
      </div>
    </div>

    <!--        <b-form-select-->
    <!--                :options="remain"-->
    <!--                required-->
    <!--                v-model="field"-->
    <!--                class="selected-form"-->
    <!--        >-->
    <!--            <template v-slot:first>-->
    <!--                <b-form-select-option v-if="remain.length > 0" value="" disabled>Selecione uma ou mais opções...</b-form-select-option>-->
    <!--                <b-form-select-option v-else value="" disabled>Não existem mais opções</b-form-select-option>-->
    <!--            </template>-->
    <!--        </b-form-select>-->
  </div>
</template>

<script>
export default {
  name: 'SortSelection',
  props: {
    options: Array,
    edit: Array,
  },
  data() {
    return {
      field: '',
      selected: [],
      remain: [],
    };
  },
  computed: {
    filterFields() {
      const filteredStories = this.remain.filter(story => {
        return story.text.toLowerCase().includes(this.field.toLowerCase());
      });
      const orderedStories = filteredStories.sort((a, b) => {
        return b.upvoted - a.upvoted;
      });
      return orderedStories;
    },
  },
  methods: {
    insertField(field) {
      if (field !== undefined && field !== '' && field !== null) {
        this.selected.push(field);
      }
      this.remain.forEach((element, index) => {
        if (field) {
          if (element.value.codigo === field.codigo) {
            this.remain.splice(index, 1);
          }
        }
      });
    },
    selectedUp(index) {
      if (index !== 0) {
        const aux = this.selected[index];
        this.selected[index] = this.selected[index - 1];
        this.selected[index - 1] = aux;
        this.$emit('selected-change', this.selected);
        this.$forceUpdate();
      }
    },
    selectedDown(index) {
      if (index !== this.selected.length - 1) {
        const aux = this.selected[index];
        this.selected[index] = this.selected[index + 1];
        this.selected[index + 1] = aux;
        this.$emit('selected-change', this.selected);
        this.$forceUpdate();
      }
    },
    selectedRemove(index) {
      this.remain.push({
        text: this.selected[index].label,
        value: {
          label: this.selected[index].label,
          codigo: this.selected[index].codigo,
        },
      });
      this.selected.splice(index, 1);
      this.field = '';
      this.$forceUpdate();
    },
  },
  watch: {
    selected() {
      this.$emit('selected-change', this.selected);
    },
  },
  mounted() {
    this.remain = this.options;
    this.selected = this.edit;
    for (let i = 0; i < this.selected.length; i++) {
      for (let j = 0; j < this.remain.length; j++) {
        if (this.remain[j].value.codigo === this.selected[i].codigo) {
          this.remain.splice(j, 1);
        }
      }
    }
  },
};
</script>

<style scoped>
#SortSelect {
}

.selected-icon {
  stroke: currentColor;
  stroke-width: 0.1em;
}
.selected-icon:hover {
  cursor: pointer;
  color: #c31d1f;
}
.selected-text {
}
.selected-text-border {
  border-left: none;
  display: grid;
  place-items: center;
}
.selected-box {
  padding: 0 1em;
  margin-bottom: 1em;
  border-radius: 0.25em;
  border: solid 1px #ced4da;
}
.selected-index {
  padding: 0 0.5em;
  color: currentColor;
  border: solid 1px currentColor;
  border-radius: 0.25em;
}
.selected-form:focus {
  box-shadow: none;
  border-color: currentColor;
}

.fields-box {
  height: 200px;
  width: 100%;
  overflow-y: scroll;
}

.line-field {
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
}

.line-field:hover {
  cursor: pointer;
  background: #efefef;
}
</style>
