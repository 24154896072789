<template>
  <div>
    <Title title="NFes Importadas" />

    <div id="TableFilter" v-show="!this.loading">
      <b-form-group label="Selecione a data inicial e final da emissão da nota">
        <div class="row">
          <div class="col-md-6">
            <b-form-datepicker
              placeholder="Data Inicial"
              v-model="data_inicial"
            >
            </b-form-datepicker>
          </div>
          <div class="col-md-6">
            <b-form-datepicker placeholder="Data Final" v-model="data_final">
            </b-form-datepicker>
          </div>
        </div>
      </b-form-group>
      <b-button
        class="btn-search"
        @click="
          search(typeof currentPage === 'undefined' ? null : currentPage, {
            data_inicial: data_inicial !== '' ? data_inicial : null,
            data_final: data_final !== '' ? data_final : null,
          })
        "
      >
        Pesquisar
      </b-button>
    </div>
    <div v-show="this.danfe_button || this.danfe_div" class="row mb-5">
      <div class="col-md-12">
        <b-button @click="showDanfe" class="btn-search"
          >{{ this.danfe_button ? 'Esconder' : 'Visualizar' }} Danfe
        </b-button>
      </div>
      <div class="col-md-12 mt-5 mb-5" v-show="this.load_pdf">
        <b-spinner v-show="this.load_pdf" variant="danger" key="danger">
        </b-spinner>
      </div>
      <pdf
        :src="this.pdf"
        class="align-self-center offset-md-2 col-md-8 text-center mt-5 nfe"
        v-show="this.pdf_visualizer"
        @loaded="showPDF"
      >
      </pdf>
    </div>
    <Table
      v-show="!this.loading && fields.length > 0"
      :rows="fields"
      :columns="columns"
      redirect_button="true"
      visualize_pdf="true"
      @redirect-button="redirect($event)"
      @visualize-pdf="visualize($event)"
    />

    <div class="text-center d-flex justify-content-center">
      <b-spinner
        v-show="this.loading"
        variant="danger"
        key="danger"
      ></b-spinner>
    </div>
    <b-pagination
      v-show="!this.loading && fields.length > 0"
      align="center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="fieldTable"
      @input="
        search(currentPage, {
          data_inicial: data_inicial !== '' ? data_inicial : null,
          data_final: data_final !== '' ? data_final : null,
        })
      "
    ></b-pagination>

    <p v-show="!this.loading && fields.length === 0">
      Nenhum registro encontrado.
    </p>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import Table from '@/components/generics/Table';
import Title from '@/components/generics/Title';
import {NFeService} from '@/services';

export default {
  name: 'Main',
  data() {
    return {
      data_inicial: '',
      data_final: '',
      // action: "new",
      loading: true,
      currentPage: 1,
      perPage: 3,
      totalRows: 0,
      fields: [],
      columns: [
        {key: 'cnpj_issuer', label: 'CNPJ Emissor', sortable: true},
        {key: 'issuer', label: 'Emissor', sortable: true},
        {key: 'date_hour', label: 'Data/Hora Emissão', sortable: true},
        {key: 'value', label: 'Valor (R$)', sortable: true},
        {key: 'action', label: 'Ações', sortable: false},
      ],
      client_base_url: '',
      pdf_visualizer: false,
      pdf: '',
      danfe_button: false,
      load_pdf: true,
      danfe_div: false,
    };
  },
  components: {
    Title,
    Table,
    pdf,
  },
  methods: {
    showPDF() {
      this.load_pdf = false;
      this.pdf_visualizer = true;
    },
    showDanfe() {
      this.danfe_button = !this.danfe_button;
      this.pdf_visualizer = !this.pdf_visualizer;
      // this.
    },
    visualize(event) {
      this.danfe_button = true;
      this.load_pdf = true;
      this.pdf_visualizer = false;
      this.danfe_div = true;
      NFeService.getDanfe(event.row).then(res => {
        new Response(res.data).arrayBuffer().then(res => {
          this.pdf = new Uint8Array(res);
        });
      });
    },
    redirect(event) {
      // console.log(this.getUrl() + "&xml=" + event.row.toString())
      window.open(this.getUrl() + '&xml=' + event.row.toString());
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    formatCpfCnpj(string) {
      return string.toString().length === 11
        ? this.formatCpf(string)
        : this.formatCnpj(string);
    },
    formatCpf(string) {
      return string
        .toString()
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    },
    formatCnpj(string) {
      return string
        .toString()
        .replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          '\$1.\$2.\$3\/\$4\-\$5',
        );
    },
    formatDate(date_hour) {
      const data_hora = date_hour.split(' ');
      const data = data_hora[0].split('-');
      return data[2] + '/' + data[1] + '/' + data[0] + ' ' + data_hora[1];
    },
    search(page, filters = null) {
      this.pdf_visualizer = false;
      this.danfe_button = false;
      this.load_pdf = true;
      this.danfe_div = false;

      let params = {
        page: page,
      };

      if (filters) {
        params = {...filters, ...params};
      }
      this.loading = true;
      NFeService.getNFes(params)
        .then(res => {
          this.fields = [];
          this.currentPage = res?.meta?.current_page;
          this.perPage = res?.meta?.per_page;
          this.totalRows = res?.meta?.total;
          res.data.forEach(nfe => {
            this.fields.push({
              issuer:
                nfe?.emissor?.emi_razao_social ||
                nfe?.emissor?.emi_nome_fantasia,
              value: this.formatPrice(nfe?.valor),
              date_hour: this.formatDate(nfe?.data_hora),
              cnpj_issuer: this.formatCpfCnpj(nfe?.emissor?.emi_cpf_cnpj),
              codigo: nfe.id,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getUrl() {
      const url = window.location.origin.split('.');

      if (url.length > 1) {
        url.shift();
      }

      return url.join('.').includes('http://')
        ? url.join('.') + '?a=entrada&b=importacaoXml'
        : 'http://' + url.join('.') + '?a=entrada&b=importacaoXml';
    },
  },
  mounted() {
    this.search(1);
  },
};
</script>

<style scoped>
#TableFilter {
  margin-bottom: 2em;
}

.filter-input {
  background-color: #ffffff;
  border: solid 1px #ced4da;
  border-left: solid 1px #c31d1f;
}

.filter-input:focus {
  background-color: #ffffff;
  border-color: #c31d1f;
  box-shadow: none;
}

.filter-icon {
  stroke: currentColor;
  width: 1.5em;
  height: 1.5em;
}

.nfe {
  border: 1px solid black;
  border-radius: 10px;
}

.btn-search {
  /*border-bottom-left-radius: 0;*/
  /*border-top-left-radius: 0;*/
  background: #c31d1f;
  border-color: #c31d1f;
}

.max-size-pdf {
  max-height: 300px;
}
</style>
