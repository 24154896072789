<template>
  <div>
    <v-data-table
        :headers="boostedHeaders"
        :items="items"
        hide-default-footer
        class="elevation-1"
        @page-count="pageCount = $event"
    >

      <template v-if="total" v-slot:body.append>
        <tr>
          <td :colspan="colTotal"></td>
          <td colspan="1">
            <span>Total: {{ totalValue }}</span>
          </td>
        </tr>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="actions">
          <div v-if="actions.includes('edit')" class="actions__edit actions--hover">
            <v-icon
                small
                class="actions--hover"
                @click="$emit('update:click-edit', item)"
            >
              mdi-pencil
            </v-icon>
          </div>

          <div v-if="actions.includes('delete')" class="actions__delete actions--hover">
            <v-icon
                small
                class="actions--hover"
                @click="$emit('update:click-delete', item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </div>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>

  </div>
</template>

<script>
export default {
  name: ' GenericTable',
  props: {
    total: {
      default: false,
    },
    total_name: {
      default: 'preco',
    },
    actions: {
      default: () => [],
      type: Array,
    },
    items: {},
    headers: {}
  },
  data() {
    return {}
  },
  mounted() {

  },
  computed: {
    colTotal() {

      let headerLength = Object.keys(this.headers).length;
      console.log(headerLength)
      if ((headerLength - 1) >= 1) return headerLength - 1;
    },

    totalValue() {
      let total = 0;
      this.items.forEach(i => total += parseFloat(i[this.total_name]));
      return total;
    },
    boostedHeaders() {
      if (this.actions.length > 0) {
        console.log([...this.headers, {text: 'Ações', value: 'actions'}]);
        return [...this.headers, {text: 'Ações', value: 'actions'}]
      }
    }
  }
}
</script>


<style scoped>
.actions {
  display: flex;
  flex-direction: row;
}

.actions--hover:hover {
  color: red;
  cursor: pointer;
  border-radius: 100%;
}
</style>