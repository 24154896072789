import router from '@/router';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import swal from 'sweetalert2';
import {ResponseError} from './types';

const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
    EmpBanco: process.env.VUE_APP_EMP,
  },
});

apiInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const bearerToken = localStorage.getItem('token');
  if (bearerToken !== null) {
    config.headers.Authorization = `Bearer ${bearerToken}`;
  }
  return config;
});

apiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<ResponseError>) => {
    const {response} = error;
    let responseDefault = {data: {error: {message: ''}}};
    if (response) {
      responseDefault = response;
    }
    if (response?.status === 422) {
      let message = 'Todos os campos são obrigatórios!';
      Object.entries(response.data.error.errors).forEach(
        ([key, value]: any) => {
          message = value[0];
        },
      );
      swal.fire('Atenção', message, 'error');
      throw response;
    }

    const message = responseDefault.data.error.message;
    swal.fire('Atenção', message, 'error');
    if (response?.status === 401) {
      localStorage.removeItem('token');
      router.push('/login');
    }
    throw response;
  },
);

export default apiInstance;
