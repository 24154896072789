import {AxiosResponse} from 'axios';
import apiInstance from '@/services/api/config';
import {NFe} from '@/models/Core/nfes/nfe';

function getNFes(params: any): Promise<NFe | null> {
  // console.log(Object.keys(params).map(
  //   key =>
  //     encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
  // ))
  const query_string =
    '?' +
    Object.keys(params)
      .filter(key => params[key] !== null)
      .map(
        key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]),
      )
      .join('&');

  return apiInstance
    .get('nfe' + query_string)
    .then(({data}: AxiosResponse<NFe>) => data)
    .catch(() => null);
}
function getDanfe(id: number): any {
  return apiInstance.get('nfe/danfe/' + id.toString(), {
    responseType: 'blob',
  });
}
const NFeService = {
  getNFes,
  getDanfe,
};

export default NFeService;
